import { useEffect, useState } from "react";
import { _fs } from "../../services/firebase/config";
import { FB_QUERYSNAPSHOT, GENERAL_RESPONSE } from "../../types/firbase.types";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../constants/firebase.constants";
import { RowProps } from "../../components/core/AppTable/types";
import { ThreeDotsIcon } from "../../assets/icons/ThreeDotsIcon";
import {
  FREQUENCY_TO_NAME,
  PAYMENT_DATA,
  UPDATE_DEFAULT_PAYMENT_DTO,
} from "../../types/payments/payments.types";
import AppSelectButton from "../../components/core/AppSelectButton/AppSelectButton";
import { Skeleton } from "@mui/material";
import { getFirebaseDataByColKeyVal } from "../../hooks/firebase/getFirebaseDataByColKeyVal";
import { PAYMENTS_ATOMS } from "./PaymentsPage.Atoms";
import { useAtom } from "jotai";
import { StyledAppSwitchTableCell } from "../../components/shared/StyledComponents/StyledAppSwitchTableCell";
import { AppSwitch } from "../../components/core/AppSwitch/AppSwitch";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";

const getSubscriptionMemberCount = async (clientId: string) => {
  const response: { [key: string]: { productId: string } } =
    await getFirebaseDataByColKeyVal(
      COLLECTIONS_TYPE.subscriptions,
      "clientId",
      clientId
    );

  const mapCount: { [key: string]: number } = {};

  Object.values(response || {})?.forEach((member: { productId: string }) => {
    if (mapCount[member.productId]) {
      mapCount[member.productId]++;
    } else {
      mapCount[member.productId] = 1;
    }
  });

  return mapCount as { [key: string]: number };
};

export const useFirebaseStreamPayments = (clientId: string) => {
  const [rows, setRows] = useState<any>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const [subscriptionMemberCount, setSubscriptionMemberCount] = useState<{
    [key: string]: number;
  }>();

  const [isSubmittingDefaultPayment, setIsSubmittingDefaultPayment] = useState<{
    [key: string]: boolean;
  }>({});

  const [selectedProductRow, setSelectedProductRow] = useAtom(
    PAYMENTS_ATOMS.selectedProductRow
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useAtom(
    PAYMENTS_ATOMS.isDeleteModalOpen
  );

  const handleOnClickDeleteIcon = (product: PAYMENT_DATA) => {
    setSelectedProductRow(product);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateDefaultPayment = async (
    productId: string,
    checked: boolean
  ) => {
    setIsSubmittingDefaultPayment({
      ...isSubmittingDefaultPayment,
      [productId]: true,
    });
    const payload: UPDATE_DEFAULT_PAYMENT_DTO = {
      productId: productId,
      clientId,
      checked: Boolean(checked),
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.updateDefaultPayment)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_RESPONSE;
        if (data.result !== "ok") {
          toast(
            "Error while updating default location [1]",
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((e) =>
        toast("Error while updating default location [2]", APP_TOASTIFY.ERROR)
      )
      .finally(() =>
        setIsSubmittingDefaultPayment({
          ...isSubmittingDefaultPayment,
          [productId]: false,
        })
      );
  };

  useEffect(() => {
    const setRolesCount = async () => {
      setSubscriptionMemberCount(await getSubscriptionMemberCount(clientId));
    };
    setRolesCount();
  }, []);

  useEffect(() => {
    const unsubscribe = _fs
      .collection(COLLECTIONS_TYPE.products)
      .where(`clientId`, "==", clientId)
      .onSnapshot(async (querySnapshot: FB_QUERYSNAPSHOT) => {
        let _rows: RowProps[] = [];
        let _row: RowProps = {} as RowProps;

        /** sort by price */
        const sortedByPrice = querySnapshot.docs.sort((a, b) => {
          const priceA = a?.data()?.price;
          const priceB = b?.data()?.price;
          return Number(priceA) - Number(priceB);
        });

        sortedByPrice.forEach((doc) => {
          const {
            paymentAccountId,
            name,
            frequency,
            price,
            productId,
            isDefaultPayment,
          } = doc?.data() as PAYMENT_DATA;

          _row = {
            id: [paymentAccountId, name, frequency, price].join(" "),
            cells: [
              name,
              String("GBP " + price),
              FREQUENCY_TO_NAME[frequency],
              subscriptionMemberCount ? (
                String(subscriptionMemberCount[productId] || 0)
              ) : (
                <Skeleton style={{ width: 20 }} />
              ),
              <StyledAppSwitchTableCell
                style={{ width: 50, display: "flex", justifyContent: "end" }}
              >
                {isSubmittingDefaultPayment[productId] ? (
                  <Skeleton style={{ width: 50, height: 20 }} />
                ) : (
                  <AppSwitch
                    containerStyles={{ width: 50 }}
                    id={productId}
                    checked={isDefaultPayment}
                    onChange={(checked: boolean) =>
                      handleUpdateDefaultPayment(productId, checked)
                    }
                    label={
                      isDefaultPayment
                        ? "Default payment"
                        : "Set as default payment"
                    }
                    labelStyles={{ textWrap: "nowrap" }}
                  />
                )}
              </StyledAppSwitchTableCell>,
              <AppSelectButton
                containerStyle={{
                  justifyContent: "end",
                }}
                title={<ThreeDotsIcon />}
                buttonsList={["Delete"]}
                buttonActions={[
                  () => handleOnClickDeleteIcon(doc?.data() as PAYMENT_DATA),
                ]}
              />,
            ],
          };
          _rows.push(_row);
        });
        setRows(_rows);
        setFetched(true);
      });
    return () => unsubscribe();
  }, [
    fetched,
    subscriptionMemberCount,
    rows.length,
    isSubmittingDefaultPayment,
  ]);

  return fetched ? rows : null;
};
