import React, { useEffect, useState } from "react";
import { TabPanel } from "../../../../components/shared/AppModalDesktopSteps/TabPanel/TabPanel";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import { AddChildrenYupSchema, CHILD_INITIAL_VALUES } from "../../constants";
import { ChildrenDTO, SubGroup } from "../../MembersPage.types";
import { useTheme } from "@mui/material";
import { AppFormikField } from "../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import {
  AppFormikSelect,
  SelectOption,
} from "../../../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";
import {
  StyledChildrenButtonsContainer,
  StyledCancel,
  StyledChildListItem,
  StyledChildrenTabContainer,
  StyledDivider,
  StyledFormFieldsContainer,
  StyledForm,
  StyledNameAndInfoIconContainer,
} from "./ChildrenTab.styles";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalSubmitButtonContainer,
} from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import EditIcon from "@mui/icons-material/Edit";
import { useAtom, useAtomValue } from "jotai";
import { MEMBERS_ATOMS } from "../../MembersPage.Atoms";
import { AppDatePicker } from "../../../../components/core/AppDatePicker/AppDatePicker";
import { Dayjs } from "dayjs";
import { dayJsToTs } from "../../../../helpers/date-time";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";
import { AddIconWithLabel } from "../../../../components/shared/AddIconWithLabel/AddIconWithLabel";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../../styles/styles.constants";
import { MemberSubGroupsTreeWithCheckboxes } from "../MemberSubGroupsTreeWithCheckboxes/MemberSubGroupsTreeWithCheckboxes";
import {
  StyledGroupAsterisks,
  StyledGroupLabel,
} from "../MemberSubGroupsTreeWithCheckboxes/MemberSubGroupsTreeWithCheckboxes.styles";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { mapMemberGroupsFromArrayToObject } from "../GroupsTab/GroupsTab.helper";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { getUniqueChildrenIds } from "./ChildrenTab.helper";
import { AppSpinner } from "../../../../components/core/AppSpinner/AppSpinner";
import { AppInlineErrorMessage } from "../../../../components/core/AppInlineErrorMessage/AppInlineErrorMessage";
import { noGroupsErrorMessage } from "../../MembersPage.constants";
import { NewChildInfoIcon } from "./NewChildInfoIcon/NewChildInfoIcon";

interface ChildrenTabProps {
  outerTabValue: number;
  handleOuterTabChange: (newValue: number) => void;
}

export const ChildrenTab = ({
  outerTabValue,
  handleOuterTabChange,
}: ChildrenTabProps) => {
  const [showChildForm, setShowChildForm] = useState(false);

  const theme = useTheme();
  const isEditMode = useAtomValue(MEMBERS_ATOMS.isEditMode);
  const products = useAtomValue(MEMBERS_ATOMS.products);
  const defaultPaymentProductId = useAtomValue(
    MEMBERS_ATOMS.defaultPaymentProductId
  );

  const [existingChildrenIds, setExistingChildrenIds] = useAtom(
    MEMBERS_ATOMS.existingChildrenIds
  );

  const [addMemberFormValues, setAddMemberFormValues] = useAtom(
    MEMBERS_ATOMS.addMemberFormValues
  );

  const [activeChild, setActiveChild] = useState<ChildrenDTO | undefined>(
    addMemberFormValues.selectedChild || undefined
  );

  const clientGroupsAndSubGroups = useAtomValue(
    MEMBERS_ATOMS.clientGroupsAndSubGroups
  );

  const { clientId } = useSelector(getClientSelector);

  const tabValue = useAtomValue(MEMBERS_ATOMS.tabValue);

  const [isLoading, setIsLoading] = useState(false);

  const [
    shouldUpdateMemberGuardianGroups,
    setShouldUpdateMemberGuardianGroups,
  ] = useState(false);

  useEffect(() => {
    if (
      shouldUpdateMemberGuardianGroups ||
      addMemberFormValues?.children?.length > 0
    ) {
      handleMemberGuardianGroups();
      setShouldUpdateMemberGuardianGroups(false);
    }
  }, [
    shouldUpdateMemberGuardianGroups,
    addMemberFormValues?.children?.length,
    outerTabValue,
  ]);

  const handleMemberGuardianGroups = () => {
    if (addMemberFormValues.children?.length > 0) {
      let subGroups: string[] =
        activeChild?.subGroups && activeChild?.subGroups?.length > 0
          ? [...activeChild?.subGroups]
          : [];
      let groups: string[] =
        activeChild?.groups && activeChild?.groups?.length > 0
          ? [...activeChild?.groups]
          : [];
      addMemberFormValues.children.forEach((child) => {
        subGroups = [...subGroups, ...(child.subGroups || [])];
        groups = [...(child.groups || [])];
      });

      const uniqueSubGroups = Array.from(new Set(subGroups));

      setAddMemberFormValues({
        ...addMemberFormValues,
        roles: {
          ...addMemberFormValues.roles,
          [String(clientId)]: {
            ...(addMemberFormValues.roles
              ? addMemberFormValues.roles[String(clientId)]
              : {}),
            Guardian: {
              ...mapMemberGroupsFromArrayToObject([...uniqueSubGroups]),
            },
          },
        },
      });
    } else {
    }
  };

  const handleOnChangeCheckedSubGroups = (
    _checkedSubGroups: string[],
    groupId: string,
    pushOrPop: "pushGroupId" | "popGroupId"
  ) => {
    let groups: string[] = [...(activeChild?.groups || [])];

    if (pushOrPop === "pushGroupId") {
      groups = [...groups, groupId];
    } else if (pushOrPop === "popGroupId") {
      groups = groups.filter((item) => item !== groupId);
    }

    const uniqueGroups = Array.from(new Set(groups));

    const childData = {
      ...(activeChild as ChildrenDTO),
      subGroups: [..._checkedSubGroups],
      groups: [...uniqueGroups],
    };

    setActiveChild({ ...childData });
  };

  useEffect(() => {
    if (defaultPaymentProductId && !isEditMode) {
      setActiveChild({
        ...activeChild,
        productId: defaultPaymentProductId,
      } as ChildrenDTO);
    }
  }, [defaultPaymentProductId, isEditMode, showChildForm]);

  /** editing existing member */
  useEffect(() => {
    if (
      (tabValue === 1 &&
        addMemberFormValues?.childrenIds &&
        addMemberFormValues?.childrenIds?.length > 0) ||
      existingChildrenIds?.length
    ) {
      setIsLoading(true);
      firebaseHttpsCallable(FIRESTORE_FUNCTIONS.getChildrenByIds)({
        childrenIds: getUniqueChildrenIds(
          addMemberFormValues?.childrenIds as string[],
          existingChildrenIds[0]
        ),
        clientId: clientId,
      })
        .then((res: { data: any }) => {
          const _children = res?.data?.data || [];

          setAddMemberFormValues({
            ...addMemberFormValues,
            children: [..._children],
            existingChildrenIds: [...existingChildrenIds],
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [tabValue]);

  return (
    <TabPanel
      value={outerTabValue}
      index={1}
      customStyle={{ maxWidth: "100%" }}
    >
      <StyledChildrenTabContainer>
        <StyleAppModalSingleStepContainer
          $customStylesDesktop={{ paddingBottom: "20px", maxWidth: "570px" }}
        >
          <StyledAppModalDesktopStepTitle style={{ marginBottom: 20 }}>
            Children Info
          </StyledAppModalDesktopStepTitle>
          {!showChildForm ? (
            <>
              {existingChildrenIds?.length < 1 && (
                <AddIconWithLabel
                  label={"ADD NEW CHILD"}
                  onClick={() => setShowChildForm(true)}
                />
              )}
              {isLoading ? (
                <AppSpinner />
              ) : (
                <div style={{ marginTop: 20, height: 300 }}>
                  {addMemberFormValues?.children?.map((child, index) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color:
                            activeChild?.index === index
                              ? theme.palette.secondary.dark[400]
                              : theme.palette.primary.main,
                        }}
                      >
                        <StyledChildListItem>
                          <li>
                            <StyledNameAndInfoIconContainer>
                              <div>
                                {`${child.firstName || ""} ${child.lastName}`}
                              </div>

                              <NewChildInfoIcon
                                childId={child.childId}
                                existingChildrenIds={existingChildrenIds || []}
                              />
                            </StyledNameAndInfoIconContainer>
                          </li>
                        </StyledChildListItem>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          {/* {!child.childId && (
                          <RecycleBinIcon onClick={() => {}} />
                        )} */}
                          {!isEditMode && !existingChildrenIds?.length && (
                            <EditIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowChildForm(true);
                                setActiveChild({ ...child, index });
                              }}
                            />
                          )}
                        </div>
                      </div>

                      <StyledDivider />
                    </>
                  ))}
                </div>
              )}

              <StyledAppModalSubmitButtonContainer>
                <AppButton
                  onClick={() => {
                    handleOuterTabChange(2);
                  }}
                >
                  Continue
                </AppButton>
              </StyledAppModalSubmitButtonContainer>
            </>
          ) : (
            <Formik
              key={activeChild?.index}
              initialValues={activeChild || { ...CHILD_INITIAL_VALUES }}
              validationSchema={AddChildrenYupSchema}
              onSubmit={(values: ChildrenDTO, { resetForm }) => {
                try {
                  const formValues = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    productId: values.productId,
                    nextPaymentDate: values.nextPaymentDate,
                  };

                  setAddMemberFormValues((prevState) => {
                    if (!isNaN(Number(values.index))) {
                      const prevChildren = [...prevState.children];
                      prevChildren[Number(values.index)] = {
                        ...activeChild,
                        ...formValues,
                      };
                      return {
                        ...prevState,
                        children: [...prevChildren],
                      };
                    } else {
                      return {
                        ...prevState,
                        children: [
                          ...prevState.children,
                          {
                            ...activeChild,
                            ...formValues,
                          },
                        ],
                      };
                    }
                  });

                  setShouldUpdateMemberGuardianGroups(true);

                  setActiveChild(undefined);
                  resetForm();
                  setShowChildForm(false);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                const _touched: FormikTouched<ChildrenDTO> = touched;

                const _errors: FormikErrors<ChildrenDTO> = errors;

                const onGroupSelectionChange = (
                  subGroup: SubGroup[],
                  checked: boolean
                ) => {
                  const groupsSet: any = new Set(values.groups);
                  const subGroupsSet: any = new Set(values.subGroups);
                  subGroup.forEach((subGroup) => {
                    setFieldValue(`subGroupId_${subGroup.subGroupId}`, checked);
                    setFieldValue(
                      `subGroupName${subGroup.subGroupName}`,
                      checked
                    );
                    setFieldValue(`groupId_${subGroup.groupId}`, checked);
                    setFieldValue(`groupName_${subGroup.groupName}`, checked);

                    if (checked) {
                      subGroupsSet.add(subGroup.subGroupId);
                      groupsSet.add(subGroup.groupId);
                    } else {
                      subGroupsSet.delete(subGroup.subGroupId);
                    }
                  });

                  setFieldValue("groups", [...groupsSet]);
                  setFieldValue("subGroups", [...subGroupsSet]);
                };

                return (
                  <StyledForm>
                    <StyledFormFieldsContainer>
                      <AppFormikField
                        isRequired={true}
                        name="firstName"
                        value={values.firstName}
                        label="First Name"
                        errorMessage={
                          _touched.firstName && _errors.firstName
                            ? String(errors.firstName)
                            : undefined
                        }
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        containerstyle={{ marginBlock: 10 }}
                      />
                      <AppFormikField
                        isRequired={true}
                        name="lastName"
                        value={values.lastName}
                        label="Last Name"
                        errorMessage={
                          _touched.lastName && _errors.lastName
                            ? String(errors.lastName)
                            : undefined
                        }
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        containerstyle={{ marginBottom: 10 }}
                      />

                      <div style={{ marginBottom: 10 }}>
                        <AppFormikSelect
                          name="productId"
                          label="Subscription"
                          value={
                            values.productId && values.productId !== "null"
                              ? values.productId
                              : "none"
                          }
                          errorMessage={
                            _touched.productId && _errors.productId
                              ? String(errors.productId)
                              : undefined
                          }
                          onChange={(e) => {
                            setFieldValue("productId", e.value);
                          }}
                          options={products as SelectOption[]}
                          placeholder="pll"
                        />
                      </div>

                      {!isEditMode &&
                        values.productId &&
                        values.productId !== "none" &&
                        values.productId !== "null" && (
                          <AppDatePicker
                            dateValue={values.nextPaymentDate}
                            onShouldDisableDate={(dayJs) => {
                              return false;
                            }}
                            onChangeDate={(dayJs: Dayjs | null) => {
                              const value = dayJsToTs(dayJs as Dayjs);
                              setFieldValue("nextPaymentDate", value);
                            }}
                            label="Subscription Start Date"
                          />
                        )}
                      <StyledDivider
                        style={{
                          borderBottom: `2px solid ${APP_COMMON_COLORS.dark[100]}`,
                          marginBlock: 0,
                          marginBottom: 20,
                        }}
                      />

                      {clientGroupsAndSubGroups?.length < 1 ? (
                        <AppInlineErrorMessage message={noGroupsErrorMessage} />
                      ) : (
                        <div style={{ marginBottom: 30, width: "98%" }}>
                          <StyledGroupLabel>
                            <StyledGroupAsterisks>*</StyledGroupAsterisks>
                            Groups
                          </StyledGroupLabel>

                          {clientGroupsAndSubGroups?.map((subGroups) => {
                            return (
                              <MemberSubGroupsTreeWithCheckboxes
                                flatTree={subGroups}
                                checkedSubGroups={[
                                  ...(activeChild?.subGroups || []),
                                ]}
                                onChangeCheckedSubGroups={(
                                  _checkedSubGroups,
                                  groupId
                                ) => {
                                  const hasCommonValue = (
                                    array1: string[],
                                    array2: string[]
                                  ) => {
                                    return array1.some((value) =>
                                      array2.includes(value)
                                    );
                                  };
                                  handleOnChangeCheckedSubGroups(
                                    _checkedSubGroups,
                                    groupId,
                                    hasCommonValue(
                                      _checkedSubGroups,
                                      Object.keys(subGroups)
                                    )
                                      ? "pushGroupId"
                                      : "popGroupId"
                                  );
                                }}
                              />
                            );
                          })}
                        </div>
                      )}
                    </StyledFormFieldsContainer>

                    <StyledChildrenButtonsContainer>
                      <StyledAppModalSubmitButtonContainer>
                        <AppButton
                          onClick={() => {
                            if (
                              !activeChild?.subGroups?.length ||
                              activeChild?.subGroups?.length < 1
                            ) {
                              return toast(
                                "Please fill all required data and select at least one sub group to continue.",
                                APP_TOASTIFY.ERROR
                              );
                            }
                            handleSubmit();
                          }}
                        >
                          Continue
                        </AppButton>
                      </StyledAppModalSubmitButtonContainer>
                      <StyledCancel
                        onClick={() => {
                          setActiveChild(undefined);
                          setShowChildForm(false);
                        }}
                      >
                        Cancel
                      </StyledCancel>
                    </StyledChildrenButtonsContainer>
                  </StyledForm>
                );
              }}
            </Formik>
          )}
        </StyleAppModalSingleStepContainer>
      </StyledChildrenTabContainer>
    </TabPanel>
  );
};
