import { useEffect, useState } from "react";
import { _fs } from "../../services/firebase/config";
import { FB_QUERYSNAPSHOT, GENERAL_RESPONSE } from "../../types/firbase.types";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../constants/firebase.constants";
import { RowProps } from "../../components/core/AppTable/types";
import { ThreeDotsIcon } from "../../assets/icons/ThreeDotsIcon";
import AppSelectButton from "../../components/core/AppSelectButton/AppSelectButton";
import {
  LOCATION_DATA,
  UPDATE_DEFAULT_CLUB_LOCATION_DTO,
} from "../../types/locations/locations.types";
import { AppSwitch } from "../../components/core/AppSwitch/AppSwitch";
import styled from "styled-components";
import { Divider, Skeleton } from "@mui/material";
import { StyledAppSwitchTableCell } from "../../components/shared/StyledComponents/StyledAppSwitchTableCell";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { toast } from "react-toastify";
import { RecycleBinIcon } from "../../assets/icons/RecycleBinIcon";
import { LOCATION_MODAL_ATOMS } from "./LocationFormModal/LocationFormModal.Atoms";
import { useAtom, useSetAtom } from "jotai";

const StyledActions = styled.div({
  display: "flex",
  justifyContent: "end",
  gap: 15,
});

export const useFirebaseStreamLocations = (clientId: string) => {
  const [rows, setRows] = useState<any>([]);
  const [fetched, setFetched] = useState<boolean>(false);
  const [isSubmittingDefaultLocation, setIsSubmittingDefaultLocation] =
    useState<{ [key: string]: boolean }>({});
  const setIsDeleteModalOpen = useSetAtom(
    LOCATION_MODAL_ATOMS.isDeleteModalOpen
  );
  const setDeletingLocationData = useSetAtom(
    LOCATION_MODAL_ATOMS.deletingLocationData
  );
  const setIsOpen = useSetAtom(LOCATION_MODAL_ATOMS.isOpen);
  const setIsEditMode = useSetAtom(LOCATION_MODAL_ATOMS.isEditMode);
  const setLocationFormData = useSetAtom(LOCATION_MODAL_ATOMS.locationFormData);

  const handleDeleteLocation = async (doc: LOCATION_DATA) => {
    setIsDeleteModalOpen(true);
    setDeletingLocationData(doc);
  };

  const handleUpdateDefaultClubLocation = async (
    id: string,
    checked: boolean
  ) => {
    setIsSubmittingDefaultLocation({
      ...isSubmittingDefaultLocation,
      [id]: true,
    });
    const payload: UPDATE_DEFAULT_CLUB_LOCATION_DTO = {
      id: id,
      clientId,
      checked: Boolean(checked),
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.updateDefaultClubLocation)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_RESPONSE;
        if (data.result !== "ok") {
          toast(
            "Error while updating default location [1]",
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((e) =>
        toast("Error while updating default location [2]", APP_TOASTIFY.ERROR)
      )
      .finally(() =>
        setIsSubmittingDefaultLocation({
          ...isSubmittingDefaultLocation,
          [id]: false,
        })
      );
  };

  useEffect(() => {
    const unsubscribe = _fs
      .collection(COLLECTIONS_TYPE.locations)
      .where(`clientId`, "==", clientId)
      .onSnapshot((querySnapshot: FB_QUERYSNAPSHOT) => {
        let _rows: RowProps[] = [];
        let _row: RowProps = {} as RowProps;

        querySnapshot.forEach((doc) => {
          const {
            title,
            addressLineOne,
            postCode,
            lat,
            lng,
            isDefaultClubLocation,
            id,
          } = doc?.data() as LOCATION_DATA;
          console.log(doc?.data());

          _row = {
            id: [title, addressLineOne, postCode, lat, lng].join(" "),
            cells: [
              title,
              addressLineOne || "",
              <StyledAppSwitchTableCell>
                {isSubmittingDefaultLocation[id] ? (
                  <Skeleton style={{ width: 120, height: 20 }} />
                ) : (
                  <AppSwitch
                    id={id}
                    checked={isDefaultClubLocation}
                    onChange={(checked: boolean) =>
                      handleUpdateDefaultClubLocation(id, checked)
                    }
                    label={`${
                      isDefaultClubLocation ? "Hide" : "Show"
                    } in settings`}
                  />
                )}
              </StyledAppSwitchTableCell>,
              <StyledActions>
                <RecycleBinIcon
                  onClick={() =>
                    handleDeleteLocation(doc.data() as LOCATION_DATA)
                  }
                />
                <Divider orientation="vertical" style={{ height: 22 }} />

                <AppSelectButton
                  containerStyle={{ justifyContent: "end" }}
                  title={<ThreeDotsIcon />}
                  buttonsList={["Edit location", "View in maps"]}
                  buttonActions={[
                    () => {
                      setIsOpen(true);
                      setIsEditMode(true);
                      setLocationFormData(doc?.data() as any);
                    },
                    () => {
                      window.open(
                        "https://maps.google.com?q=" + lat + "," + lng
                      );
                    },
                  ]}
                />
              </StyledActions>,
            ],
          };
          _rows.push(_row);
        });
        setRows(_rows);
        setFetched(true);
      });
    return () => unsubscribe();
  }, [fetched, isSubmittingDefaultLocation]);

  return fetched ? rows : null;
};
