import { Form } from "formik";
import styled from "styled-components";

export const StyledForm = styled(Form)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});

export const StyledFormFieldsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  paddingLeft: 20,
  overflow: "auto",
  height: 430,
  "@media only screen and (min-width: 1200px)": {
    height: 390,
  },
});

export const StyledChildrenButtonsContainer = styled.div({
  display: "flex",
  gap: 25,
  alignItems: "center",
  marginTop: "auto",
});

export const StyledCancel = styled.div(({ theme }) => ({
  color: theme.colorPrimary,
  cursor: "pointer",
  marginBottom: 25,
  fontWeight: 600,
  height: "fit-content",
  "&:hover": {
    textDecoration: "underline",
  },
}));
