import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { a11yProps } from "./TabPanel/TabPanel";
import { StyledAppModalDesktopStepsContainer } from "./AppModalDesktopSteps.styles";

interface AppModalDesktopStepsProps {
  tabsTitle: string;
  tabs: string[];
  tabValue: number;
  OnChangeTabChange: (e: number) => void;
}

export const AppModalDesktopSteps: React.FC<AppModalDesktopStepsProps> = ({
  ...props
}) => {
  const handleOuterTabChange = (newValue: number) => {
    props.OnChangeTabChange(newValue);
  };

  const theme = useTheme();

  return (
    <StyledAppModalDesktopStepsContainer>
      <Typography
        fontWeight={500}
        variant="h6"
        mb={8}
        style={{ whiteSpace: "nowrap" }}
      >
        {props.tabsTitle}
      </Typography>
      <Tabs
        orientation="vertical"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        value={props.tabValue}
        onChange={(_: React.SyntheticEvent<Element, Event> | null, e: number) =>
          handleOuterTabChange(e)
        }
        aria-label="Vertical tabs example"
        sx={{
          "& .MuiTabs-flexContainer": {
            alignItems: "start",
          },
          "& button": {
            fontWeight: 400,
            fontSize: "14px",
          },
          "& .Mui-selected": {
            color: theme.palette.primary.main,
          },
        }}
      >
        {props.tabs.map((tab, index) => (
          <Tab
            key={index}
            disableRipple
            iconPosition="start"
            icon={
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "4px",
                  background:
                    props.tabValue === index
                      ? theme.palette.primary.main
                      : theme.palette.common.white,
                  border:
                    props.tabValue === index
                      ? `2px solid ${theme.palette.primary.main}`
                      : `2px solid ${theme.palette.primary.main}`,
                  color:
                    props.tabValue === index
                      ? theme.palette.common.white
                      : theme.palette.primary.main,
                }}
              >
                {index + 1}
              </Box>
            }
            sx={{
              color:
                props.tabValue === index
                  ? theme.palette.common.white
                  : theme.palette.primary.main,
              gap: "2px",
              textTransform: "none",
              padding: 0,
              display: "flex",
              justifyContent: "start",
              // textWrap: "nowrap",
            }}
            label={tab}
            disabled={index > props.tabValue}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </StyledAppModalDesktopStepsContainer>
  );
};
