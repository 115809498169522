import { useEffect, useState } from "react";
import { _fs } from "../../../services/firebase/config";
import { RowProps } from "../../../components/core/AppTable/types";
import styled, { useTheme } from "styled-components";
import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import { ThreeDotsIcon } from "../../../assets/icons/ThreeDotsIcon";
import { MEMBERS } from "../../../types/members/members.types";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../../constants/firebase.constants";
import { useLocation } from "react-router-dom";
import AppSelectButton from "../../../components/core/AppSelectButton/AppSelectButton";
import { ChildrenDTO, MemberFormValues } from "../MembersPage.types";
import { MemberStatusCell } from "./MemberStatusCell/MemberStatusCell";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { MEMBERS_ATOMS } from "../MembersPage.Atoms";
import { VIEW_MEMBER_MODAL_ATOMS } from "../ViewModals/ViewMemberModal/ViewMemberModal.Atoms";
import { VIEW_CHILD_MODAL_ATOMS } from "../ViewModals/ViewChildModal/ViewChildModal.Atoms";
import { firebaseHttpsCallable } from "../../../hooks/firebase/firebase.helper";
import { APP_TOASTIFY } from "../../../styles/styles.constants";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import { getPaymentStatus } from "../MemberPage.helper";
import { WhereFilter } from "../../../hooks/firebase/firebase.types";
import { MemberNameCell } from "./MemberNameCell/MemberNameCell";
import { EditChildModalAtoms } from "../EditChildModal/EditChildModal.atoms";
import { ChildStatusCell } from "./ChildStatusCell/ChildStatusCell";

export const StyledLogoImage = styled.img({
  display: "block",
  width: 35,
});

export const StyledActions = styled.div({
  display: "flex",
  justifyContent: "end",
  gap: 15,
});

export const StyledClientName = styled.div({
  alignSelf: "center",
  fontSize: 14,
  color: APP_COMMON_COLORS.yourzown.colorPrimary,
});

const getRolesDisplay = (
  rolesString: string,
  isClientAdmin: boolean,
  childId?: string
) => {
  if (childId) {
    return "Child";
  }

  const admin = isClientAdmin ? "Admin" : "";
  const rolesDisplay = rolesString
    ?.split(",")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(", ");
  const comma = admin && rolesDisplay ? ", " : "";
  return rolesDisplay ? `${admin}${comma}${rolesDisplay}` : admin;
};

const sortMembers = (a: MEMBERS | ChildrenDTO, b: MEMBERS | ChildrenDTO) => {
  const NameA = `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}`;
  const NameB = `${b.firstName.toLowerCase()} ${b.lastName.toLowerCase()}`;

  if (NameA < NameB) {
    return -1;
  } else if (NameA > NameB) {
    return 1;
  } else {
    return 0;
  }
};

export const useFirebaseStreamMembers = (
  clientId: string,
  setSelectedEditRow: React.Dispatch<
    React.SetStateAction<MemberFormValues | null>
  >,
  setTabValue: React.Dispatch<React.SetStateAction<number>>
) => {
  const [rows, setRows] = useState<any>([]);
  const theme = useTheme();
  const { state }: { state: { filterBy: { value: string } } } = useLocation();
  const setIsEditMode = useSetAtom(MEMBERS_ATOMS.isEditMode);

  const location = useLocation();
  const [subGroupId, setSubGroupId] = useState("");
  const setIsViewMemberModalOpen = useSetAtom(
    VIEW_MEMBER_MODAL_ATOMS.isViewMemberModalOpen
  );
  const setViewMemberId = useSetAtom(VIEW_MEMBER_MODAL_ATOMS.viewMemberId);
  const setIsViewChildModalOpen = useSetAtom(
    VIEW_CHILD_MODAL_ATOMS.isViewChildModalOpen
  );
  const setViewChildId = useSetAtom(VIEW_CHILD_MODAL_ATOMS.viewChildId);

  const memberSubscription = useAtomValue(MEMBERS_ATOMS.memberSubscription);

  const setIsAddEditMemberModalOpen = useSetAtom(
    MEMBERS_ATOMS.isAddEditMemberModalOpen
  );

  const setExistingChildrenIds = useSetAtom(MEMBERS_ATOMS.existingChildrenIds);

  const setIsEditChildrenModalOpen = useSetAtom(
    EditChildModalAtoms.isEditChildrenModalOpen
  );

  const setChildData = useSetAtom(EditChildModalAtoms.childData);

  const [membersData, setMembersData] = useState<MEMBERS[]>([]);
  const [childrenData, setChildrenData] = useState<ChildrenDTO[]>([]);

  const [isFetchMembers, setIsFetchMembers] = useState(false);
  const [isFetchChildren, setIsFetchChildren] = useState(false);

  const handleDeactivateMember = async (
    memberUid: string,
    clientId: string
  ) => {
    toast("Deactivating member...", APP_TOASTIFY.SUCCESS);

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.deactivateMember)({
      memberId: String(memberUid),
      clientId: String(clientId),
    })
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Member deactivated", APP_TOASTIFY.SUCCESS);
        } else {
          toast("Something while deactivating member", APP_TOASTIFY.ERROR);
        }
      })
      .catch((err: any) => {
        console.log({ err });
        toast("Something while deactivating member [2]", APP_TOASTIFY.ERROR);
      });
  };

  const handleActivateMember = async (memberUid: string, clientId: string) => {
    toast("Activating member...", APP_TOASTIFY.SUCCESS);

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.reactivateMember)({
      memberId: memberUid,
      clientId: clientId,
    })
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Member activated", APP_TOASTIFY.SUCCESS);
        } else {
          toast("Something while activating member", APP_TOASTIFY.ERROR);
        }
      })
      .catch((err: any) => {
        console.log({ err });
        toast("Something while activating member [2]", APP_TOASTIFY.ERROR);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSubGroupId(params.get("subGroupId") || "");
  }, [location.search]);

  useEffect(() => {
    const unsubscribeMembersRef = _fs
      .collection(COLLECTIONS_TYPE.members)
      .where(`clientsIds.${clientId}`, "==", true)
      .onSnapshot((docSnapshot) => {
        let _membersData: MEMBERS[] = [];

        docSnapshot.docs.forEach((doc) => {
          const _data = doc.data() as MEMBERS;
          const roles: any = _data.roles as MEMBERS;

          if (subGroupId) {
            if (roles && roles[clientId]) {
              const subGroups = Object.values(roles[clientId]);
              let combinedSubGroups: string[] = [];

              if (subGroups && subGroups.length > 0) {
                subGroups.forEach((_subGroupObj) => {
                  if (_subGroupObj) {
                    const _subGroupsIds = Object.keys(_subGroupObj);
                    if (_subGroupsIds && _subGroupsIds?.length > 0) {
                      _subGroupsIds?.forEach((_subGroupsId: string) => {
                        combinedSubGroups.push(_subGroupsId);
                      });
                    }
                  }
                });
              }
              if (combinedSubGroups?.includes(subGroupId)) {
                _membersData.push(doc.data() as MEMBERS);
              }
            }
          } else {
            _membersData.push(doc.data() as MEMBERS);
          }
        });
        setMembersData(_membersData);
        setIsFetchMembers(true);
      });

    return () => unsubscribeMembersRef();
  }, [
    isFetchMembers,
    state?.filterBy?.value,
    subGroupId,
    Object.values(memberSubscription || {}).length,
  ]);

  useEffect(() => {
    let childrenRef = _fs
      .collection(COLLECTIONS_TYPE.children)
      .where(`clientId`, "==", clientId);

    if (subGroupId) {
      childrenRef = childrenRef.where(
        "subGroups",
        WhereFilter["array-contains"],
        subGroupId
      );
    }

    const unsubscribeChildrenRef = childrenRef.onSnapshot((docSnapshot) => {
      let _childrenData: ChildrenDTO[] = [];

      docSnapshot.docs.forEach((doc) => {
        const _data = doc.data() as ChildrenDTO;

        _childrenData.push(_data);
      });
      setChildrenData(_childrenData);
    });

    return () => unsubscribeChildrenRef();
  }, [
    state?.filterBy?.value,
    subGroupId,
    Object.values(memberSubscription || {}).length,
  ]);

  useEffect(() => {
    let _rows: RowProps[] = [];

    if (membersData && childrenData) {
      [...membersData, ...childrenData]
        .sort?.(sortMembers)
        ?.forEach((doc: MEMBERS | ChildrenDTO) => {
          const {
            uid,
            firstName,
            lastName,
            email,
            roles,
            isClientAdmin,
            status,
            isDeactivated,
            ...rest
          } = doc;

          const childId = "childId" in doc ? doc.childId : undefined;
          const childrenIds =
            "childrenIds" in doc ? doc.childrenIds : undefined;

          const membersIds = "membersIds" in doc ? doc.membersIds : undefined;

          const rolesString = new Set();

          Object.entries(
            (roles?.[clientId] || {}) as {
              [key: string]: { [key: string]: boolean };
            }
          ).forEach(
            ([roleName, role]: [string, { [key: string]: boolean }]) => {
              Object.values(role).forEach((value) => {
                if (value === true) {
                  rolesString.add(roleName);
                }
              });
            }
          );

          const searchOfChildAdminNamesAndEmails: string = membersData
            .filter((member) => membersIds?.includes(member.uid))
            .map(
              ({ firstName, lastName, email }) =>
                `${firstName} ${lastName} ${email}`
            )
            .join(" ");

          const _row = {
            id: [
              uid,
              firstName,
              lastName,
              email,
              isClientAdmin?.[String(clientId)] ? "admin" : "",
              isDeactivated && isDeactivated[clientId] ? "deactivated" : status,
              getPaymentStatus(memberSubscription && memberSubscription[uid]),
              ...Array.from(rolesString),
              childId || "",
              childId
                ? `child ${
                    searchOfChildAdminNamesAndEmails || "Child has no guardian"
                  }`
                : "",
            ].join(" "),
            cells: [
              <MemberNameCell
                key={`${childId}-${email}`} // Ensure key is unique and stable
                firstName={firstName}
                lastName={lastName}
                childId={childId}
                membersIds={membersIds}
                email={email}
                allMembersOfClient={membersData}
              />,
              <div>
                {getRolesDisplay(
                  Array.from(rolesString)?.toString(),
                  isClientAdmin?.[String(clientId)],
                  childId
                )}
              </div>,
              memberSubscription ? (
                getPaymentStatus(memberSubscription[uid])
              ) : (
                <Skeleton width={30} />
              ),
              childId ? (
                <ChildStatusCell
                  childId={childId}
                  membersIds={membersIds}
                  allMembersOfClient={membersData}
                />
              ) : (
                <MemberStatusCell
                  status={status}
                  email={email}
                  isDeactivated={isDeactivated}
                  clientId={clientId}
                />
              ),
              <AppSelectButton
                containerStyle={{ justifyContent: "end" }}
                title={<ThreeDotsIcon />}
                buttonsList={
                  childId
                    ? ["View profile", "Edit child", "Add additional guardian"]
                    : [
                        "View profile",
                        "Edit profile",
                        isDeactivated && isDeactivated[clientId]
                          ? "Activate"
                          : "Deactivate",
                      ]
                }
                buttonActions={
                  childId
                    ? [
                        () => {
                          setIsViewChildModalOpen(true);
                          setViewChildId(childId);
                          setIsViewMemberModalOpen(false);
                          setViewMemberId(undefined);
                        },
                        () => {
                          setIsEditChildrenModalOpen(true);
                          setChildData(doc);
                        },
                        () => {
                          setIsAddEditMemberModalOpen(true);
                          setExistingChildrenIds([childId]);
                        },
                      ]
                    : [
                        () => {
                          setIsViewMemberModalOpen(true);
                          setViewMemberId(uid);
                          setIsViewChildModalOpen(false);
                          setViewChildId(undefined);
                        },
                        () => {
                          setIsAddEditMemberModalOpen(true);
                          setIsEditMode(true);
                          setTabValue(0);
                          setSelectedEditRow({
                            ...doc,
                            /** reset children old structure */
                            children: [],
                          } as MemberFormValues);
                        },
                        () => {
                          isDeactivated && isDeactivated[clientId]
                            ? handleActivateMember(uid, clientId)
                            : handleDeactivateMember(uid, clientId);
                        },
                      ]
                }
              />,
            ],
          };
          _rows.push(_row);
        });
      setRows(_rows);
      setIsFetchChildren(true);
    }
  }, [membersData, childrenData, isFetchChildren, memberSubscription]);

  return isFetchMembers && isFetchChildren ? rows : null;
};
