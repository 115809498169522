import {
  ChildrenDTO,
  RolesAndSubGroups,
} from "../../pages/MembersPage/MembersPage.types";
import { CLIENT_STATE } from "../client/client.types";

interface YOURZOWN_PERMISSIONS {
  clients: {
    edit: boolean;
    view: boolean;
  };
}

export enum MEMBER_STATUS {
  active = "active",
}

export interface MEMBERS {
  clientsIds: { [key: string]: boolean };
  createdAt: number;
  email: string;
  firstName: string;
  isClientAdmin: { [key: string]: boolean };
  lastName: string;
  roles: { [x: string]: any };
  yourzownPermissions: YOURZOWN_PERMISSIONS;
  uid: string;
  status: MEMBER_STATUS;
  children: ChildrenDTO[];
  childrenIds?: string[];
  gender?: string;
  dateOfBirth?: number;
  medicalConditions?: string;
  middleName?: string;
  photoConsent?: boolean;
  phoneNumber?: string;
  isDeactivated?: { [key: string]: boolean };
}

export interface INVITE_MEMBER_DTO {
  isClientAdmin: { [key: string]: boolean };
  email: string;
  firstName: string;
  lastName: string;
  roles?: Record<string, Record<string, boolean> | undefined>;
  additionalInfo?: Record<string, Record<string, boolean> | undefined>;
  client: Partial<CLIENT_STATE>;
  flatData?: { [key: string]: any };
  children?: ChildrenDTO[];
  productId?: string;
  subGroupsContact?: { [key: string]: string[] };
  existingChildrenIds?: string[];
}

export interface RESEND_INVITE_MEMBER_EMAIL {
  email: string;
  client: CLIENT_STATE;
  baseUrl: string;
}
