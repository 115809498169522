import { useSelector } from "react-redux";
import { TitleBodyButtonWidget } from "../../../../components/widgets/TitleBodyButtonWidget/TitleBodyButtonWidget";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../../styles/styles.constants";
import { GENERAL_RESPONSE } from "../../../../types/firbase.types";
import { useState } from "react";

export const SetupPaymentCard = () => {
  const client = useSelector(getClientSelector);
  const [isLoading, setIsLoading] = useState(false);
  const isActiveAccount = client.paymentAccountStatus === "active";
  return (
    <TitleBodyButtonWidget
      title="Payment Information"
      body="Your payment information is managed in Stripe, follow the link below to make changes."
      buttonLabel={isActiveAccount ? "EDIT PAYMENT INFO" : "SETUP PAYMENT INFO"}
      isLoading={isLoading}
      onButtonClick={() => {
        setIsLoading(true);
        firebaseHttpsCallable(FIRESTORE_FUNCTIONS.createClientAccount)({
          clientId: client.clientId,
        })
          .then((res) => {
            const data = res.data as GENERAL_RESPONSE;
            const newWindow = window.open(data.data?.url, "_blank");
            if (
              !newWindow ||
              newWindow?.closed ||
              typeof newWindow?.closed === "undefined"
            ) {
              alert(
                "Redirection to Stripe was blocked by your browser. Please allow pop-ups and redirects for this site in your browser settings."
              );
            }
          })
          .catch(() => {
            toast("Something went wrong [3]", APP_TOASTIFY.ERROR);
          })
          .finally(() => setIsLoading(false));
      }}
    />
  );
};
