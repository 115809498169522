import { MEMBERS } from "../../../../types/members/members.types";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { MemberStatusCell } from "../MemberStatusCell/MemberStatusCell";

interface ChildStatusCellProps {
  childId: string;
  membersIds?: string[];
  allMembersOfClient: MEMBERS[];
}

export const ChildStatusCell = (props: ChildStatusCellProps) => {
  const client = useSelector(getClientSelector);

  const members = props.allMembersOfClient.filter((member) => {
    if (props?.membersIds?.includes(member.uid)) {
      return member;
    }
  });

  if (members.length === 1) {
    return (
      <MemberStatusCell
        status={members[0].status}
        email={members[0].email}
        isDeactivated={members[0].isDeactivated}
        clientId={client.clientId}
      />
    );
  }

  if (members.length > 1) {
    let hasOneGuardianActivate = false;
    members.forEach((member) => {
      if (
        !member?.isDeactivated ||
        (member?.isDeactivated &&
          !member?.isDeactivated[String(client.clientId)])
      ) {
        hasOneGuardianActivate = true;
      }
    });

    return <div>{hasOneGuardianActivate ? "Active" : "Deactivated"}</div>;
  }

  return <div>-</div>;
};
