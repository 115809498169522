import { useEffect, useState } from "react";
import { getFirebaseDataCollectionDoc } from "../../../../../../hooks/firebase/getFirebaseDataCollectionDoc";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../../../redux/client/clientSlice";
import { COLLECTIONS_TYPE } from "../../../../../../constants/firebase.constants";
import {
  PermissionsValues,
  PRE_DEFINED_ROLES_PERMISSIONS,
} from "../../../../../RolesPage/CreateRoleModal/RolesPermissionsStep/RolesPermissionsStep.types";
import styled from "styled-components";
import { Circle } from "@mui/icons-material";
import { APP_COMMON_COLORS } from "../../../../../../providers/AppThemeProvider";
import { useAtom } from "jotai";
import { MEMBERS_ATOMS } from "../../../../MembersPage.Atoms";

export const StyledTitle = styled.div({
  color: APP_COMMON_COLORS.dark[600],
  fontWeight: 400,
  fontSize: 14,
  marginBottom: 12,
});

const StyledPermissionContainer = styled.div({
  display: "flex",
  gap: 20,
  alignItems: "center",
  marginBottom: 5,
});

const StyledFlex = styled.div({
  display: "flex",
  gap: 10,
  alignItems: "center",
});

const StyledLabel = styled.div({
  color: APP_COMMON_COLORS.dark[400],
  fontWeight: 400,
  fontSize: 12,
});

export const GroupsSideMenuPermissions = () => {
  const client = useSelector(getClientSelector);
  const [roles, setRoles] = useState<{ [k: string]: any }>();
  const [currentActiveRole, setCurrentActiveRole] = useAtom(
    MEMBERS_ATOMS.currentActiveRole
  );

  const getCircleColor = (permission: PermissionsValues) => {
    if (currentActiveRole && roles) {
      if (roles[currentActiveRole][permission]) {
        return APP_COMMON_COLORS.common.lightGreen;
      }
    }
    return APP_COMMON_COLORS.common.lightRed;
  };

  useEffect(() => {
    getFirebaseDataCollectionDoc(
      COLLECTIONS_TYPE.roles,
      String(client.clientId)
    ).then((data) => {
      const sortedData = Object.fromEntries(
        Object?.entries(data || {}).sort((a, b) => {
          return a[0]?.localeCompare(b[0]);
        })
      );
      setRoles(sortedData);
    });
  }, []);

  return (
    <div>
      <StyledTitle>Permissions</StyledTitle>

      <StyledFlex style={{ display: "flex", marginBottom: 20 }}>
        <StyledFlex style={{ gap: 5 }}>
          <Circle
            style={{ fontSize: 10, color: APP_COMMON_COLORS.common.lightGreen }}
          />
          <StyledLabel>Enabled</StyledLabel>
        </StyledFlex>
        <StyledFlex style={{ gap: 5 }}>
          <Circle
            style={{ fontSize: 10, color: APP_COMMON_COLORS.common.lightRed }}
          />
          <StyledLabel>Disabled</StyledLabel>
        </StyledFlex>
      </StyledFlex>

      {Object.values(PRE_DEFINED_ROLES_PERMISSIONS).map((permission) => {
        return (
          <StyledPermissionContainer key={permission.value}>
            <Circle
              style={{ fontSize: 10, color: getCircleColor(permission.value) }}
            />
            <div
              key={permission.value}
              style={{ color: APP_COMMON_COLORS.dark[600] }}
            >
              {permission.label}
            </div>
          </StyledPermissionContainer>
        );
      })}
    </div>
  );
};
