import { useEffect, useState } from "react";
import { AppTable } from "../../components/core/AppTable/AppTable";
import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { useFirebaseStreamClubDocs } from "./useFirebaseStreamClubDocs";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { PageAddButton } from "../../components/shared/PageAddButton/PageAddButton";
import styled from "styled-components";
import { ClubDocFormModal } from "./ClubDocFormModal";
import { AppSpinner } from "../../components/core/AppSpinner/AppSpinner";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../constants/firebase.constants";
import { GENERAL_RESPONSE } from "../../types/firbase.types";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { DeleteModal } from "../../components/shared/DeleteModal/DeleteModal";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import { CLUB_DOC } from "../../types/clubDocs/clubDocs.types";
const columns = [
  {
    cell: "Doc",
  },
  {
    cell: <div style={{ display: "flex", justifyContent: "end" }}>Actions</div>,
  },
];

export const StyledPageAddButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ClubDocsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const client = useSelector(getClientSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState<CLUB_DOC | null>(null);

  const rows: any[] = useFirebaseStreamClubDocs(
    String(client?.clientId),
    (rowData: CLUB_DOC) => {
      setIsEditMode(true);
      setSelectedRow(rowData);
      setIsModalOpen(true);
    },
    (rowData: CLUB_DOC) => {
      setSelectedRow(rowData);
      setIsDeleteModalOpen(true);
    }
  );
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
    setSelectedRow(null);
  };

  const onDeleteClubDoc = () => {
    setIsDeleting(true);

    const payload: { clientId: string; clubDocId: string } = {
      clubDocId: String(selectedRow?.clubDocId),
      clientId: String(selectedRow?.clientId),
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.deleteClubDoc)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_RESPONSE;
        if (data.result === "ok") {
          toast("Club Doc Deleted Successfully", APP_TOASTIFY.SUCCESS);
        } else {
          toast("Something Went Wrong", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) => toast("Something Went Wrong", APP_TOASTIFY.ERROR))
      .finally(() => {
        setIsDeleting(false);
        setSelectedRow(null);
        setIsDeleteModalOpen(false);
      });
  };

  useEffect(() => {
    rows && setIsLoading(false);
  }, [rows]);

  if (isLoading) {
    return (
      <StyledPageContainer>
        <AppSpinner />
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer>
      <PageAddButton
        text={"Add club doc"}
        onClick={() => setIsModalOpen(true)}
      />

      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          title={"Delete Club Doc"}
          deleteBtnLabel={"DELETE CLUB DOC"}
          onDeleteButton={onDeleteClubDoc}
          onCloseDeleteModal={() => {
            setIsDeleting(false);
            setSelectedRow(null);
            setIsDeleteModalOpen(false);
          }}
          deleteContent={{
            title: "YES, DELETE THIS CLUB DOC",
            subTitle: String(selectedRow?.docTitle),
            icon: <LocalPoliceIcon style={{ color: "#666666" }} />,
          }}
          isLoading={isDeleting}
        />
      )}

      {isModalOpen && (
        <ClubDocFormModal
          handleCloseModal={handleCloseModal}
          isModalOpen={isModalOpen}
          isEditMode={isEditMode}
          clubDocData={selectedRow}
        />
      )}

      <AppTable
        columns={columns}
        rows={rows}
        showPagination={false}
        showTotalCount={false}
      />
    </StyledPageContainer>
  );
};
