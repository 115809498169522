import { AppModal } from "../../components/core/AppModal/AppModal";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import styled from "styled-components";
import { AppModalMobileSteps } from "../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { StyledAppModalDesktopStepTitle } from "../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { AppFormikField } from "../../components/core/AppFormik/AppFormikField/AppFormikField";
import { StyledAppModalSubmitButtonContainer } from "../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppButton } from "../../components/core/AppButton/AppButton";
import {
  firebaseHttpsCallable,
  uploadImageToStorage,
} from "../../hooks/firebase/firebase.helper";
import {
  COLLECTIONS_TYPE,
  FIRESTORE_FUNCTIONS,
} from "../../constants/firebase.constants";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { useState } from "react";
import { GENERAL_RESPONSE } from "../../types/firbase.types";
import { useUserIdentity } from "../../hooks/useUserIdentity/useUserIdentity";
import { CLUB_DOC } from "../../types/clubDocs/clubDocs.types";
import { AppUploadImageWithPreview } from "../../components/core/AppUploadImageWithPreview/AppUploadImageWithPreview";

export const StyledClubDocFormWrapper = styled.div({
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
});

export const StyledClubDocFormContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    width: 600,
  },
});

export const StyledForm = styled(Form)({
  height: "58vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@media only screen and (min-width: 1200px)": {
    height: "50vh",
  },
});

const schema = Yup.object().shape({
  docTitle: Yup.string().required("Required"),
  docText: Yup.string().required("Required"),
  docUrl: Yup.string().required("Required"),
});

interface ClubDocFormValues {
  clientId: string;
  clubDocId: string;
  docTitle: string;
  docText: string;
  docUrl: string;
}

export const ClubDocFormModal = ({
  handleCloseModal,
  isModalOpen,
  isEditMode,
  clubDocData,
}: {
  handleCloseModal: () => void;
  isModalOpen: boolean;
  isEditMode: boolean;
  clubDocData: CLUB_DOC | null;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { clientId } = useSelector(getClientSelector);
  const { user } = useUserIdentity();

  const handleSubmit = async (values: ClubDocFormValues) => {
    if (!isLoading) {
      setIsLoading(true);
      let docUrl: any = values.docUrl;
      if (docUrl instanceof File) {
        const storagePath = `${COLLECTIONS_TYPE.clients}/${clientId}/club-docs`;

        docUrl = await uploadImageToStorage(
          values.docUrl,
          storagePath,
          `${Date.now()}-${docUrl.name}`
        );
      }

      firebaseHttpsCallable(
        isEditMode
          ? FIRESTORE_FUNCTIONS.updateClubDoc
          : FIRESTORE_FUNCTIONS.addClubDoc
      )({
        ...values,
        docUrl,
        clientId,
      })
        .then((res) => {
          const data = res.data as GENERAL_RESPONSE;
          if (data.result === "ok") {
            toast(
              isEditMode
                ? "Club Doc Updated Successfully"
                : "Club Doc Added Successfully",
              APP_TOASTIFY.SUCCESS
            );
            handleCloseModal();
          } else {
            toast("Something Went Wrong", APP_TOASTIFY.ERROR);
          }
        })
        .catch((e) => {
          toast("Something Went Wrong", APP_TOASTIFY.ERROR);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <AppModal closeModal={handleCloseModal} isOpen={isModalOpen}>
      <StyledClubDocFormWrapper>
        <StyledClubDocFormContainer>
          <AppModalMobileSteps tabValue={0} titlesOfSteps={["Add club doc"]} />
          <StyledAppModalDesktopStepTitle style={{ marginBottom: 60 }}>
            {isEditMode ? "Edit club doc" : "Add club doc"}
          </StyledAppModalDesktopStepTitle>

          <Formik
            enableReinitialize={true}
            initialValues={
              {
                clientId,
                createdBy: user.uid,
                clubDocId: clubDocData?.clubDocId || "",
                docText: clubDocData?.docText || "",
                docTitle: clubDocData?.docTitle || "",
                docUrl: clubDocData?.docUrl || "",
              } as ClubDocFormValues
            }
            validationSchema={schema}
            onSubmit={(values: ClubDocFormValues) => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, values, setFieldValue, handleBlur }) => {
              const _touched: FormikTouched<ClubDocFormValues> = touched;
              const _errors: FormikErrors<ClubDocFormValues> = errors;

              return (
                <StyledForm>
                  <AppFormikField
                    isRequired={true}
                    name="docTitle"
                    value={values.docTitle || ""}
                    label="Doc Title"
                    errorMessage={
                      _touched.docTitle && _errors.docTitle
                        ? String(errors.docTitle)
                        : undefined
                    }
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("docTitle", e.target.value);
                    }}
                    type="text"
                    containerstyle={{ marginBottom: 25 }}
                  />
                  <AppFormikField
                    isRequired={true}
                    name="docText"
                    value={values.docText || ""}
                    label="Doc Text"
                    errorMessage={
                      _touched.docText && _errors.docText
                        ? String(errors.docText)
                        : undefined
                    }
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("docText", e.target.value);
                    }}
                    type="text"
                    containerstyle={{ marginBottom: 15 }}
                    rows={4}
                  />
                  <div style={{ marginBottom: 25 }}>
                    <AppUploadImageWithPreview
                      hidePreview={true}
                      onChange={(e: File) => {
                        setFieldValue("docUrl", e);
                      }}
                      onDelete={() => {
                        setFieldValue("docUrl", "");
                      }}
                      id={"docUrl"}
                      initialMessages={{
                        primary: "Upload Doc",
                      }}
                      currentImage={values.docUrl}
                      extensions="application/pdf,.pdf,.doc,.docx,.jpeg,.jpg,.png,.bmp"
                      defaultName={values.docTitle}
                      errorMessages={[
                        "Please upload one of the following formats: PDF, DOC, DOCX, PNG, or JPEG.",
                        "Please upload a file within 10MB size limit.",
                      ]}
                    />
                  </div>

                  <StyledAppModalSubmitButtonContainer>
                    <AppButton isLoading={isLoading} type="submit">
                      {isEditMode ? "Edit club doc" : "Add club doc"}
                    </AppButton>
                  </StyledAppModalSubmitButtonContainer>
                </StyledForm>
              );
            }}
          </Formik>
        </StyledClubDocFormContainer>
      </StyledClubDocFormWrapper>
    </AppModal>
  );
};
