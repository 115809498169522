import { APP_COMMON_COLORS } from "../../../providers/AppThemeProvider";
import DangerousIcon from "@mui/icons-material/Dangerous";

export const AppInlineErrorMessage = (props: { message: string }) => {
  return (
    <div
      style={{
        fontWeight: 500,
        fontSize: 16,
        height: "35vh",
        display: "flex",
        gap: 10,
        color: APP_COMMON_COLORS.error.main,
      }}
    >
      <DangerousIcon color="error" />
      <span style={{ marginTop: 2 }}>{props.message}</span>
    </div>
  );
};
