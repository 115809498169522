import { useAtom } from "jotai";
import { AppModalDesktopSteps } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import { AppModalMobileSteps } from "../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalFooterButtonContainer,
  StyledAppModalStepsContainer,
} from "../../../components/shared/StyledComponents/StyledAppModalComponents";
import { GROUP_MODAL_ATOMS } from "./GroupFormModal.Atoms";
import { StyledAppModalDesktopStepTitle } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { AppFormikField } from "../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppButton } from "../../../components/core/AppButton/AppButton";
import { SubGroupsTree } from "../SubGroupsTree/AddEditSubGroupsTree/AddEditSubGroupsTree";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../redux/client/clientSlice";
import { firebaseHttpsCallable } from "../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../constants/firebase.constants";
import { SUB_GROUPS, GROUP } from "../../../types/groups/groups.types";
import { APP_TOASTIFY } from "../../../styles/styles.constants";
import { toast } from "react-toastify";
import { GENERAL_RESPONSE } from "../../../types/firbase.types";

export const GroupFormModal = () => {
  const [isOpen, setIsOpen] = useAtom(GROUP_MODAL_ATOMS.isOpen);
  const [groupName, setGroupName] = useAtom(GROUP_MODAL_ATOMS.groupName);
  const [groupId, setGroupId] = useAtom(GROUP_MODAL_ATOMS.groupId);
  const [isEditMode, setIsEditMode] = useAtom(GROUP_MODAL_ATOMS.isEditMode);
  const [newGroupData, setNewGroupData] = useAtom(
    GROUP_MODAL_ATOMS.newGroupData
  );
  const [isPublished, setIsPublished] = useAtom(GROUP_MODAL_ATOMS.isPublished);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const client = useSelector(getClientSelector);

  const getModalTitle = () => {
    return isEditMode ? "Edit group" : "Add group";
  };

  const handleOnSubmit = () => {
    if (!groupName) {
      toast("Please add name to group", APP_TOASTIFY.ERROR);
      return;
    }

    setIsSubmitting(true);

    let payload: GROUP = {
      createdAt: Date.now(),
      clientId: String(client.clientId),
      groupName: String(groupName),
      groupId: String(groupId),
      subGroups: (newGroupData?.subGroups as SUB_GROUPS) || {},
    };

    /** main subgroup */
    payload.subGroups = {
      ...payload.subGroups,
      [groupId]: {
        subGroupId: groupId,
        subGroupName: groupName,
        parentId: groupId,
        groupId,
      },
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.addGroupAndSubGroups)(payload)
      .then((res) => {
        const data = res.data as GENERAL_RESPONSE;
        if (data.result === "ok") {
          toast("Group Added", APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast("Error while adding group [1]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) => toast("Error while adding group [2]", APP_TOASTIFY.ERROR))
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    if (!isEditMode) {
      const newGroupId = `group_${String(Date.now())}`;
      setGroupId(newGroupId);
    }
  }, [isEditMode]);

  return (
    <StyledAppModalStepsContainer>
      <AppModalMobileSteps tabsLength={1} tabValue={0} />
      <AppModalDesktopSteps
        tabs={[]}
        tabsTitle={""}
        OnChangeTabChange={() => {}}
        tabValue={0}
      />
      <StyleAppModalSingleStepContainer style={{ paddingBottom: 0 }}>
        <StyledAppModalDesktopStepTitle>
          {getModalTitle()}
        </StyledAppModalDesktopStepTitle>

        <div>
          <AppFormikField
            isRequired={true}
            name="groupName"
            value={groupName}
            label="Group name"
            onChange={(e) => setGroupName(e.target.value)}
            type="text"
            containerstyle={{ marginBottom: 10 }}
          />
        </div>

        <div style={{ height: 280, overflow: "auto" }}>
          <SubGroupsTree />
        </div>

        <StyledAppModalFooterButtonContainer>
          <AppButton onClick={handleOnSubmit} isLoading={isSubmitting}>
            Submit
          </AppButton>
        </StyledAppModalFooterButtonContainer>
      </StyleAppModalSingleStepContainer>
    </StyledAppModalStepsContainer>
  );
};
