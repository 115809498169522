import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { AppButton } from "../../components/core/AppButton/AppButton";
import { _fs } from "../../services/firebase/config";
import { APP_COMMON_COLORS } from "../../providers/AppThemeProvider";
import { AuthLayout } from "../../components/shared/AuthLayout/AuthLayout";
import { AppRadioButtonGroup } from "../../components/core/AppFormik/AppFormikField/AppRadioButton";
import {
  StyledBody,
  StyledRadioButtonsContainer,
  StyledSubTitle,
} from "./AttendEventPage.styles";
import { useLocation } from "react-router-dom";
import { FIRESTORE_FUNCTIONS } from "../../constants/firebase.constants";
import dayjs from "dayjs";
import { firebaseHttpsCallable } from "../../hooks/firebase/firebase.helper";
import { APP_TOASTIFY } from "../../styles/styles.constants";
import { toast } from "react-toastify";

export const AttendEventPage: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteId = queryParams.get("inviteId");
  const [invitationData, setInvitationData] = useState<any>(null);
  const [eventData, setEventData] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [paymentURL, setPaymentURL] = useState("");
  const [isAttending, setIsAttending] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(true);

  const generatePaymentLink = async (invitation: any) => {
    try {
      const res: any = await firebaseHttpsCallable(
        FIRESTORE_FUNCTIONS.createOneTimePaymentLink
      )({
        target: "EVENT",
        eventId: invitation?.eventId,
        clientId: invitation?.clientId,
        memberId: invitation?.uid,
        isMemberAttending: true,
        attending: true,
        isExternalUser: true,
      });

      setPaymentURL(res?.data?.data?.url);
      setSubmitted(true);
      console.log(res);
    } catch (error) {
      console.log(error);
      toast("Failed to submit your response", APP_TOASTIFY.ERROR);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (typeof isAttending === "boolean") {
      firebaseHttpsCallable(
        isAttending
          ? FIRESTORE_FUNCTIONS.attendingEvent
          : FIRESTORE_FUNCTIONS.notAttendingEvent
      )({
        member: {
          ...eventData?.rsvp?.members?.[invitationData.uid],
          attending: isAttending,
        },
        eventId: invitationData?.eventId,
        clientId: invitationData?.clientId,
        attending: isAttending,
        isFree: invitationData?.isFree,
        isExternalInvitation: true,
      })
        .then(async () => {
          if (isAttending) {
            await generatePaymentLink(invitationData);
          } else {
            setSubmitted(true);
          }
        })
        .catch((error) => {
          console.log(error);
          toast("Failed to submit your response", APP_TOASTIFY.ERROR);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.getInvitationDetails)({
      invitationId: inviteId,
    })
      .then(async (res: any) => {
        const invitation = res?.data?.data?.invitation;
        const invitationPayment = res?.data?.data?.invitationPayment;
        const eventData = res?.data?.data?.eventData;

        setInvitationData(invitation);

        const isPaid =
          invitationPayment?.[invitation?.uid]?.[invitation?.uid] === "paid";
        if (isPaid) {
          setSubmitted(true);
          setIsAttending(true);
        }

        setEventData(eventData);

        if (
          typeof eventData?.rsvp?.members?.[invitation?.uid]
            ?.isMemberAttending === "boolean" ||
          typeof eventData?.rsvp?.members?.[invitation?.uid]?.attending ===
            "boolean"
        ) {
          setSubmitted(true);
          setIsAttending(
            eventData?.rsvp?.members?.[invitation?.uid]?.attending ||
              eventData?.rsvp?.members?.[invitation?.uid]?.isMemberAttending
          );

          if (
            (eventData?.rsvp?.members?.[invitation?.uid]?.attending ||
              eventData?.rsvp?.members?.[invitation?.uid]?.isMemberAttending) &&
            !isPaid
          ) {
            await generatePaymentLink(invitation);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [inviteId]);

  if (isLoading) return null;
  return (
    <AuthLayout
      title="Welcome To"
      subtitle="YourZown"
      iconSubtitle={`Event: ${eventData?.eventName}`}
      children={
        <div>
          <StyledSubTitle>
            {dayjs(eventData?.fromTimeStamp).format("DD MMMM YYYY, hh:mm A")}
          </StyledSubTitle>
          <StyledSubTitle
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://maps.google.com?q=" +
                  invitationData?.eventLocation?.lat +
                  "," +
                  invitationData?.eventLocation?.lng
              )
            }
          >
            {invitationData?.eventLocation?.addressLineOne}
          </StyledSubTitle>
          {submitted ? (
            <div>
              <StyledBody>
                You responded by {isAttending ? "going" : "not going"}
              </StyledBody>
              {!invitationData.isFree && paymentURL && (
                <AppButton
                  onClick={() => window.open(paymentURL, "_blank")}
                  customStyles={{
                    background: APP_COMMON_COLORS.yourzown.colorPrimary,
                    borderColor: APP_COMMON_COLORS.yourzown.colorPrimary,
                    "&:hover": {
                      background: APP_COMMON_COLORS.yourzown.colorPrimary,
                      border: `1px solid ${theme.white}`,
                    },
                  }}
                >
                  {`PAY £${eventData.cost}`}
                </AppButton>
              )}
            </div>
          ) : (
            <>
              <StyledBody>
                You have been invited to attend this event, will you attend?
              </StyledBody>

              <AppRadioButtonGroup
                options={[
                  { label: "Going", value: true },
                  { label: "Not going", value: false },
                ]}
                value={isAttending}
                onChangeValue={(e) => setIsAttending(e)}
                customContainerStyles={StyledRadioButtonsContainer}
                customErrorStyles={{ display: "none" }}
              />

              <AppButton
                onClick={handleSubmit}
                isLoading={isSubmitting}
                type="submit"
                customStyles={{
                  background: APP_COMMON_COLORS.yourzown.colorPrimary,
                  borderColor: APP_COMMON_COLORS.yourzown.colorPrimary,
                  "&:hover": {
                    background: APP_COMMON_COLORS.yourzown.colorPrimary,
                    border: `1px solid ${theme.white}`,
                  },
                }}
              >
                SUBMIT
              </AppButton>
            </>
          )}
          {invitationData?.eventLocation?.locationDetails && (
            <StyledSubTitle
              style={{ marginTop: "20px" }}
              dangerouslySetInnerHTML={{
                __html: invitationData?.eventLocation?.locationDetails,
              }}
            ></StyledSubTitle>
          )}
        </div>
      }
    />
  );
};
