import styled, { useTheme } from "styled-components";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { MEMBERS } from "../../../../types/members/members.types";

interface MemberNameCellProps {
  firstName: string;
  lastName: string;
  childId?: string;
  membersIds?: string[];
  email?: string;
  allMembersOfClient: MEMBERS[];
}

const StyledNameContainer = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 20,
});

export const MemberNameCell = (props: MemberNameCellProps) => {
  const theme = useTheme();
  const client = useSelector(getClientSelector);
  const [membersData, setMembersData] = useState<MEMBERS[]>([]);

  useEffect(() => {
    if (props.childId && props.membersIds && props.membersIds.length > 0) {
      const filteredMembers = props.allMembersOfClient.filter((member) =>
        props.membersIds?.includes(member.uid)
      );
      setMembersData(filteredMembers);
    }
  }, [props.childId, props.membersIds, props.allMembersOfClient]);

  /** is child */
  if (props.childId) {
    if (membersData.length < 1) {
      return (
        <StyledNameContainer>
          <AccountCircleIcon style={{ color: theme.colorPrimary }} />
          <div>
            <div>{`${props.firstName} ${props.lastName}`}</div>
            <div style={{ color: theme.colorPrimary, fontStyle: "italic" }}>
              Child has no guardian
            </div>
          </div>
        </StyledNameContainer>
      );
    }
    return (
      <StyledNameContainer>
        <AccountCircleIcon style={{ color: theme.colorPrimary }} />
        <div>
          <div>{`${props.firstName} ${props.lastName}`}</div>
          <div style={{ color: theme.colorPrimary }}>
            {membersData.map((member) => (
              <div key={member.email}>
                {member.firstName} {member.lastName} ({member.email})
              </div>
            ))}
          </div>
        </div>
      </StyledNameContainer>
    );
  }

  /** is member */
  return (
    <StyledNameContainer>
      <AccountCircleIcon style={{ color: theme.colorPrimary }} />
      <div>
        <div>{`${props.firstName} ${props.lastName}`}</div>
        <div style={{ color: theme.colorPrimary }}>{props.email}</div>
      </div>
    </StyledNameContainer>
  );
};
