import { AppModal } from "../../../components/core/AppModal/AppModal";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../redux/client/clientSlice";
import { SUBSCRIPTION_ATOMS } from "./SubscriptionFormModal.Atom";
import { useAtom } from "jotai";
import styled from "styled-components";
import { AppModalMobileSteps } from "../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { StyledAppModalDesktopStepTitle } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import {
  ADD_SUBSCRIPTION_PRODUCT_DTO,
  DEV_FREQUENCIES,
  FREQUENCY_TO_NAME,
  PAYMENT_FREQUENCY,
} from "../../../types/payments/payments.types";
import * as Yup from "yup";
import { AppFormikField } from "../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { StyledAppModalSubmitButtonContainer } from "../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppButton } from "../../../components/core/AppButton/AppButton";
import { AppFormikSelect } from "../../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";
import { firebaseHttpsCallable } from "../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../constants/firebase.constants";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../styles/styles.constants";
import { useState } from "react";
import { getUser } from "../../../redux/user/userSlice";

export const StyledSubscriptionFormWrapper = styled.div({
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
});

export const StyledSubscriptionFormContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    width: 600,
  },
});

export const StyledForm = styled(Form)({
  height: "58vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@media only screen and (min-width: 1200px)": {
    height: "50vh",
  },
});

interface SubscriptionInputs {
  name: string | undefined;
  price: number | undefined;
  frequency: PAYMENT_FREQUENCY | undefined;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  price: Yup.number()
    .required("Required")
    .min(0, "Invalid input")
    .typeError("Invalid input"),
  frequency: Yup.string().required("Required"),
});

export const SubscriptionFormModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(SUBSCRIPTION_ATOMS.isModalOpen);
  const clientData = useSelector(getClientSelector);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(getUser);
  const SUBSCRIPTION_FREQUENCIES = FREQUENCY_TO_NAME;
  const handleOnSubmit = async (values: SubscriptionInputs) => {
    setIsLoading(true);
    const payload: ADD_SUBSCRIPTION_PRODUCT_DTO = {
      name: String(values.name),
      frequency: values.frequency as PAYMENT_FREQUENCY,
      price: Number(values.price),
      paymentAccountId: String(clientData.paymentAccountId),
      clientId: String(clientData.clientId),
      updatedBy: String(user.uid),
    };

    await firebaseHttpsCallable(FIRESTORE_FUNCTIONS.addSubscriptionProduct)({
      ...payload,
    })
      .then((res: any) => {
        if (res?.data?.result === "ok") {
          toast("Success", APP_TOASTIFY.SUCCESS);
          setIsModalOpen(false);
        } else {
          toast("Error adding new subscription [1]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((err: any) => {
        toast("Error adding new subscription [2]", APP_TOASTIFY.ERROR);
        setIsModalOpen(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!isModalOpen) {
    return <></>;
  }

  return (
    <AppModal closeModal={() => setIsModalOpen(false)} isOpen={isModalOpen}>
      <StyledSubscriptionFormWrapper>
        <StyledSubscriptionFormContainer>
          <AppModalMobileSteps
            tabValue={0}
            titlesOfSteps={["Add subscription"]}
          />
          <StyledAppModalDesktopStepTitle style={{ marginBottom: 60 }}>
            Add subscription
          </StyledAppModalDesktopStepTitle>

          <Formik
            enableReinitialize={true}
            initialValues={
              {
                name: undefined,
                price: undefined,
                frequency: undefined,
              } as SubscriptionInputs
            }
            validationSchema={schema}
            onSubmit={(values: SubscriptionInputs) => {
              handleOnSubmit(values);
            }}
          >
            {({
              errors,
              touched,
              values,
              setFieldValue,
              submitCount,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => {
              const _touched: FormikTouched<SubscriptionInputs> = touched;
              const _errors: FormikErrors<SubscriptionInputs> = errors;

              return (
                <StyledForm>
                  <AppFormikField
                    isRequired={true}
                    name="name"
                    value={values.name || ""}
                    label="NAME"
                    errorMessage={
                      _touched.name && _errors.name
                        ? String(errors.name)
                        : undefined
                    }
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                    type="text"
                    containerstyle={{ marginBottom: 25 }}
                  />

                  <AppFormikField
                    isRequired={true}
                    name="price"
                    value={values.price || ""}
                    label="PRICE"
                    errorMessage={
                      _touched.price && _errors.price
                        ? String(errors.price)
                        : undefined
                    }
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("price", e.target.value);
                    }}
                    type="text"
                    containerstyle={{ marginBottom: 25 }}
                  />

                  <AppFormikSelect
                    isRequired
                    name="frequency"
                    label="FREQUENCY"
                    value={values.frequency as any}
                    errorMessage={
                      _touched.frequency && _errors.frequency
                        ? String(errors.frequency)
                        : undefined
                    }
                    onChange={(e: any) => {
                      setFieldValue("frequency", e.value);
                    }}
                    options={[
                      {
                        value: PAYMENT_FREQUENCY.monthly,
                        label:
                          SUBSCRIPTION_FREQUENCIES[PAYMENT_FREQUENCY.monthly],
                      },
                      {
                        value: PAYMENT_FREQUENCY.quarterly,
                        label:
                          SUBSCRIPTION_FREQUENCIES[PAYMENT_FREQUENCY.quarterly],
                      },
                      {
                        value: PAYMENT_FREQUENCY.semiAnnually,
                        label:
                          SUBSCRIPTION_FREQUENCIES[
                            PAYMENT_FREQUENCY.semiAnnually
                          ],
                      },
                      {
                        value: PAYMENT_FREQUENCY.annually,
                        label:
                          SUBSCRIPTION_FREQUENCIES[PAYMENT_FREQUENCY.annually],
                      },
                    ]}
                  />

                  <StyledAppModalSubmitButtonContainer>
                    <AppButton isLoading={isLoading} onClick={handleSubmit}>
                      Save
                    </AppButton>
                  </StyledAppModalSubmitButtonContainer>
                </StyledForm>
              );
            }}
          </Formik>
        </StyledSubscriptionFormContainer>
      </StyledSubscriptionFormWrapper>
    </AppModal>
  );
};
