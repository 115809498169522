import { useAtom, useAtomValue } from "jotai";
import { AppModal } from "../../../components/core/AppModal/AppModal";
import { EditChildModalAtoms } from "./EditChildModal.atoms";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalStepsContainer,
  StyledAppModalSubmitButtonContainer,
} from "../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppModalMobileSteps } from "../../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { AppModalDesktopSteps } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import { StyledAppModalDesktopStepTitle } from "../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import {
  StyledCancel,
  StyledChildrenButtonsContainer,
  StyledForm,
  StyledFormFieldsContainer,
} from "./EditChildModal.styles";
import { AppFormikField } from "../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { ChildrenDTO } from "../MembersPage.types";
import * as Yup from "yup";
import {
  AppFormikSelect,
  SelectOption,
} from "../../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";
import { MEMBERS_ATOMS } from "../MembersPage.Atoms";
import { StyledDivider } from "../../../components/shared/StyledComponents/StyledDivider";
import {
  StyledGroupAsterisks,
  StyledGroupLabel,
} from "../Tabs/MemberSubGroupsTreeWithCheckboxes/MemberSubGroupsTreeWithCheckboxes.styles";
import { MemberSubGroupsTreeWithCheckboxes } from "../Tabs/MemberSubGroupsTreeWithCheckboxes/MemberSubGroupsTreeWithCheckboxes";
import { AppButton } from "../../../components/core/AppButton/AppButton";
import { toast } from "react-toastify";
import { APP_TOASTIFY } from "../../../styles/styles.constants";
import { AppSpinner } from "../../../components/core/AppSpinner/AppSpinner";
import { firebaseHttpsCallable } from "../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../constants/firebase.constants";
import { GENERAL_RESPONSE } from "../../../types/firbase.types";
import { useState } from "react";

export const AddChildrenYupSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
});

export const CHILD_INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  nextPaymentDate: Date.now(),
};

export const EditChildModal = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [isEditChildModalOpen, setIsEditChildModalOpen] = useAtom(
    EditChildModalAtoms.isEditChildrenModalOpen
  );

  const [childData, setChildData] = useAtom(EditChildModalAtoms.childData);
  const products = useAtomValue(MEMBERS_ATOMS.products);
  const clientGroupsAndSubGroups = useAtomValue(
    MEMBERS_ATOMS.clientGroupsAndSubGroups
  );

  const handleOnChangeCheckedSubGroups = (
    _checkedSubGroups: string[],
    groupId: string,
    pushOrPop: "pushGroupId" | "popGroupId"
  ) => {
    console.log("checkedSubGroups", _checkedSubGroups);
    console.log("groupId", groupId);
    let groups: string[] = [...(childData?.groups || [])];

    if (pushOrPop === "pushGroupId") {
      groups = [...groups, groupId];
    } else if (pushOrPop === "popGroupId") {
      groups = groups.filter((item) => item !== groupId);
    }

    const uniqueGroups = Array.from(new Set(groups));

    const _childData = {
      ...(childData as ChildrenDTO),
      subGroups: [..._checkedSubGroups],
      groups: [...uniqueGroups],
    };

    setChildData({ ..._childData });
  };

  const handleSubmitEditChild = (values: ChildrenDTO) => {
    setIsLoading(true);

    const payload: ChildrenDTO = {
      ...childData,
      firstName: values.firstName,
      lastName: values.lastName,
      productId: values.productId,
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.updateChildById)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_RESPONSE;
        if (data.result === "ok") {
          window.location.reload();
        } else {
          toast("Error while child profile [1]", APP_TOASTIFY.ERROR);
        }
      })
      .catch((e) =>
        toast("Error while updating child profile [2]", APP_TOASTIFY.ERROR)
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <AppModal
      isOpen={isEditChildModalOpen}
      closeModal={() => setIsEditChildModalOpen(false)}
      $customContentStyle={{ padding: 0 }}
    >
      <StyledAppModalStepsContainer>
        <AppModalMobileSteps
          tabValue={1}
          titlesOfSteps={["Edit child"]}
          customSmallTitle="Edit child"
          hideStepsTitle={true}
        />
        <AppModalDesktopSteps
          tabs={[]}
          tabsTitle=""
          OnChangeTabChange={() => {}}
          tabValue={1}
        />
        <StyleAppModalSingleStepContainer>
          <StyledAppModalDesktopStepTitle>
            Edit child
          </StyledAppModalDesktopStepTitle>
          {isLoading ? (
            <AppSpinner />
          ) : (
            <Formik
              initialValues={
                childData || {
                  ...CHILD_INITIAL_VALUES,
                }
              }
              validationSchema={AddChildrenYupSchema}
              onSubmit={handleSubmitEditChild}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }) => {
                const _touched: FormikTouched<ChildrenDTO> = touched;
                const _errors: FormikErrors<ChildrenDTO> = errors;

                return (
                  <StyledForm>
                    <StyledFormFieldsContainer>
                      <AppFormikField
                        isRequired={true}
                        name="firstName"
                        value={values.firstName}
                        label="First Name"
                        errorMessage={
                          _touched.firstName && _errors.firstName
                            ? String(errors.firstName)
                            : undefined
                        }
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        containerstyle={{ marginBlock: 10 }}
                      />
                      <AppFormikField
                        isRequired={true}
                        name="lastName"
                        value={values.lastName}
                        label="Last Name"
                        errorMessage={
                          _touched.lastName && _errors.lastName
                            ? String(errors.lastName)
                            : undefined
                        }
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        containerstyle={{ marginBottom: 10 }}
                      />
                      <div style={{ marginBottom: 10 }}>
                        <AppFormikSelect
                          name="productId"
                          label="Subscription"
                          value={
                            values.productId && values.productId !== "null"
                              ? values.productId
                              : "none"
                          }
                          errorMessage={
                            _touched.productId && _errors.productId
                              ? String(errors.productId)
                              : undefined
                          }
                          onChange={(e) => {
                            setFieldValue("productId", e.value);
                          }}
                          options={products as SelectOption[]}
                        />
                      </div>
                      <StyledDivider style={{ marginBottom: 16 }} />

                      {clientGroupsAndSubGroups?.length < 1 ? (
                        <AppSpinner />
                      ) : (
                        <div style={{ marginBottom: 30, width: "98%" }}>
                          <StyledGroupLabel>
                            <StyledGroupAsterisks>*</StyledGroupAsterisks>
                            Groups
                          </StyledGroupLabel>

                          {clientGroupsAndSubGroups?.map((subGroups) => {
                            return (
                              <MemberSubGroupsTreeWithCheckboxes
                                flatTree={subGroups}
                                checkedSubGroups={[
                                  ...(childData?.subGroups || []),
                                ]}
                                onChangeCheckedSubGroups={(
                                  _checkedSubGroups,
                                  groupId
                                ) => {
                                  const hasCommonValue = (
                                    array1: string[],
                                    array2: string[]
                                  ) => {
                                    return array1.some((value) =>
                                      array2.includes(value)
                                    );
                                  };
                                  handleOnChangeCheckedSubGroups(
                                    _checkedSubGroups,
                                    groupId,
                                    hasCommonValue(
                                      _checkedSubGroups,
                                      Object.keys(subGroups)
                                    )
                                      ? "pushGroupId"
                                      : "popGroupId"
                                  );
                                }}
                              />
                            );
                          })}
                        </div>
                      )}
                    </StyledFormFieldsContainer>

                    <StyledChildrenButtonsContainer>
                      <StyledAppModalSubmitButtonContainer>
                        <AppButton
                          onClick={() => {
                            if (
                              !childData?.subGroups?.length ||
                              childData?.subGroups?.length < 1
                            ) {
                              return toast(
                                "Please fill all required data and select at least one sub group to continue.",
                                APP_TOASTIFY.ERROR
                              );
                            }
                            handleSubmit();
                          }}
                        >
                          Continue
                        </AppButton>
                      </StyledAppModalSubmitButtonContainer>
                      <StyledCancel
                        onClick={() => {
                          setChildData(undefined);
                          setIsEditChildModalOpen(false);
                        }}
                      >
                        Cancel
                      </StyledCancel>
                    </StyledChildrenButtonsContainer>
                  </StyledForm>
                );
              }}
            </Formik>
          )}
        </StyleAppModalSingleStepContainer>
      </StyledAppModalStepsContainer>
    </AppModal>
  );
};
