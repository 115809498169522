import { useState, useEffect } from "react";
import { AppModal } from "../../components/core/AppModal/AppModal";
import { useAutoFill } from "../../hooks/useAutoFill/useAutoFill";
import { ADD_MEMBER_STEPPER_TABS, MEMBER_INITIAL_VALUES } from "./constants";
import {
  StyledAddButtonContainer,
  StyledSearchContainer,
  TempStyledAppSideContainer,
} from "./MembersPage.styles";
import { AddMemberTab } from "./Tabs/AddMemberTab/AddMemberTab";
import { GroupsTab } from "./Tabs/GroupsTab/GroupsTab";
import { StyledPageContainer } from "../../components/shared/StyledComponents/StyledPageContainer";
import { PageAddButton } from "../../components/shared/PageAddButton/PageAddButton";
import { AppModalDesktopSteps } from "../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps";
import { StyledAppModalStepsContainer } from "../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppTable } from "../../components/core/AppTable/AppTable";
import { useFirebaseStreamMembers } from "./StreamMembers/useFirebaseStreamMembers";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../redux/client/clientSlice";
import { AppSpinner } from "../../components/core/AppSpinner/AppSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { AppModalMobileSteps } from "../../components/shared/AppModalMobileSteps/AppModalMobileSteps";
import { ChildrenTab } from "./Tabs/ChildrenTab/ChildrenTab";
import { HIDDEN_ELEMENT_STYLES } from "../../styles/styles.constants";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { MEMBERS_ATOMS } from "./MembersPage.Atoms";
import { COLLECTIONS_TYPE } from "../../constants/firebase.constants";
import { addProductIdToMemberAndChildren } from "./MemberPage.helper";
import { getFirebaseDataByColKeyVal } from "../../hooks/firebase/getFirebaseDataByColKeyVal";
import { _fs } from "../../services/firebase/config";
import { FB_QUERYSNAPSHOT } from "../../types/firbase.types";
import { GROUP, SUB_GROUPS } from "../../types/groups/groups.types";
import { ViewMemberModal } from "./ViewModals/ViewMemberModal/ViewMemberModal";
import { VIEW_MEMBER_MODAL_ATOMS } from "./ViewModals/ViewMemberModal/ViewMemberModal.Atoms";
import { ViewChildModal } from "./ViewModals/ViewChildModal/ViewChildModal";
import { VIEW_CHILD_MODAL_ATOMS } from "./ViewModals/ViewChildModal/ViewChildModal.Atoms";
import { useGetMembersSubscriptions } from "./StreamMembers/useGetMembersSubscriptions";
import { sortTreesRowsByName } from "./Tabs/tabs.helper";
import { GroupsSideMenu } from "./Tabs/GroupsTab/GroupsSideMenu/GroupsSideMenu";
import { EditChildModal } from "./EditChildModal/EditChildModal";
import { EditChildModalAtoms } from "./EditChildModal/EditChildModal.atoms";
import { PAYMENT_DATA } from "../../types/payments/payments.types";
import { SelectOption } from "../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";

let columns = [
  {
    cell: <div>MEMBER</div>,
  },
  {
    cell: <div>ROLE</div>,
  },
  {
    cell: <div style={{ width: 125 }}>PAYMENT</div>,
  },
  {
    cell: <div>STATUS</div>,
  },
  {
    cell: <div>Actions</div>,
  },
];

export const MembersPage = () => {
  const client = useSelector(getClientSelector);
  useGetMembersSubscriptions(String(client.clientId));

  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isAutoFill, autofillInputProps } = useAutoFill();

  const [addMemberFormValues, setAddMemberFormValues] = useAtom(
    MEMBERS_ATOMS.addMemberFormValues
  );

  const [selectedEditRow, setSelectedEditRow] = useAtom(
    MEMBERS_ATOMS.selectedEditRow
  );
  const [tabValue, setTabValue] = useAtom(MEMBERS_ATOMS.tabValue);
  const [innerTabsValue, setInnerTabsValue] = useState(0);
  const [isAddEditMemberModalOpen, setIsAddEditMemberModalOpen] = useAtom(
    MEMBERS_ATOMS.isAddEditMemberModalOpen
  );
  const [rolesAndSubGroups, setRolesAndSubGroups] = useAtom(
    MEMBERS_ATOMS.rolesAndSubGroups
  );
  const setSubGroupsContact = useSetAtom(MEMBERS_ATOMS.subGroupsContact);
  const setCurrentActiveRole = useSetAtom(MEMBERS_ATOMS.currentActiveRole);

  const setClientGroupsAndSubGroups = useSetAtom(
    MEMBERS_ATOMS.clientGroupsAndSubGroups
  );
  const isViewChildModalOpen = useAtomValue(
    VIEW_CHILD_MODAL_ATOMS.isViewChildModalOpen
  );

  const [existingChildrenIds, setExistingChildrenIds] = useAtom(
    MEMBERS_ATOMS.existingChildrenIds
  );

  const setProducts = useSetAtom(MEMBERS_ATOMS.products);
  const rows: any[] = useFirebaseStreamMembers(
    String(client?.clientId),
    setSelectedEditRow,
    setTabValue
  );
  const tabsData = ADD_MEMBER_STEPPER_TABS;
  const [isEditMode, setIsEditMode] = useAtom(MEMBERS_ATOMS.isEditMode);
  const handleTabChange = (newValue: number) => {
    setTabValue(newValue);
  };

  const handleInnerTabChange = (newValue: number) => {
    setInnerTabsValue(newValue);
  };
  const setDefaultPaymentProductId = useSetAtom(
    MEMBERS_ATOMS.defaultPaymentProductId
  );

  const handleCloseAddEditMemberModal = () => {
    setTabValue(0);
    setInnerTabsValue(0);
    setRolesAndSubGroups({});
    setAddMemberFormValues(MEMBER_INITIAL_VALUES);
    setIsAddEditMemberModalOpen(false);
    setSelectedEditRow(null);
    setProducts(undefined);
    setIsEditMode(false);
    setSubGroupsContact({});
    setDefaultPaymentProductId(undefined);
    setClientGroupsAndSubGroups([]);
    setCurrentActiveRole("");
    setExistingChildrenIds([]);
  };

  const handleOpenModal = () => setIsAddEditMemberModalOpen(true);

  const location = useLocation();
  const [subGroupName, setSubGroupName] = useState("");

  const isViewMemberModalOpen = useAtomValue(
    VIEW_MEMBER_MODAL_ATOMS.isViewMemberModalOpen
  );

  const isEditChildModalOpen = useAtomValue(
    EditChildModalAtoms.isEditChildrenModalOpen
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSubGroupName(params.get("subGroupName") || "");
  }, [location.search]);

  useEffect(() => {
    rows && setIsLoading(false);
  }, [rows]);

  useEffect(() => {
    if (selectedEditRow && client.clientId) {
      const getAndSetSubscriptionData = async () => {
        const getProducts = await getFirebaseDataByColKeyVal(
          COLLECTIONS_TYPE.subscriptions,
          "memberId",
          String(selectedEditRow.uid)
        ).then((res) => {
          if (res) {
            setAddMemberFormValues({
              ...addMemberFormValues,
              ...addProductIdToMemberAndChildren(selectedEditRow, res),
            });
          } else {
            setAddMemberFormValues({
              ...addMemberFormValues,
              ...selectedEditRow,
            });
          }
        });
        return getProducts;
      };

      getAndSetSubscriptionData();
    }
  }, [
    selectedEditRow,
    Object.keys(rolesAndSubGroups).length,
    isAddEditMemberModalOpen,
  ]);

  useEffect(() => {
    if (
      isAddEditMemberModalOpen ||
      isViewMemberModalOpen ||
      isViewChildModalOpen ||
      isEditChildModalOpen
    ) {
      const unsubscribe = _fs
        .collection(COLLECTIONS_TYPE.groups)
        .doc(client.clientId)
        .collection(COLLECTIONS_TYPE.clientGroups)
        .onSnapshot(async (querySnapshot: FB_QUERYSNAPSHOT) => {
          let _allSubGroupsOfClient: SUB_GROUPS[] = [];
          querySnapshot.forEach((doc) => {
            const { subGroups } = doc?.data() as GROUP;
            _allSubGroupsOfClient.push(subGroups || {});
          });

          /** sorting by subgroupName */
          let arr: any = [];
          _allSubGroupsOfClient.sort(sortTreesRowsByName).map((subGroup) => {
            arr.push(subGroup);
          });

          setClientGroupsAndSubGroups([...arr]);
        });
      return () => unsubscribe();
    }
  }, [
    isAddEditMemberModalOpen,
    isViewMemberModalOpen,
    isViewChildModalOpen,
    isEditChildModalOpen,
  ]);

  useEffect(() => {
    const getProducts = async () => {
      const _products: { [key: string]: PAYMENT_DATA } =
        await getFirebaseDataByColKeyVal(
          COLLECTIONS_TYPE.products,
          "clientId",
          String(client.clientId)
        );

      if (Object.values(_products || {}).length > 0) {
        let mappedProducts: SelectOption[] = [];
        Object.values(_products).map((_p) => {
          mappedProducts.push({
            label: _p.name,
            value: _p.productId,
          });
          if (_p.isDefaultPayment) {
            setDefaultPaymentProductId(_p.productId);
          }
        });
        setProducts([{ label: "None", value: "none" }, ...mappedProducts]);
      } else {
        setProducts([{ label: "None", value: "none" }]);
      }
    };
    getProducts();
  }, [isAddEditMemberModalOpen, isEditChildModalOpen]);

  const getTitleMemberInfo = () => {
    if (isEditMode) {
      return "Edit member";
    }
    if (existingChildrenIds && existingChildrenIds.length > 0) {
      return "Additional guardian";
    } else {
      return "Add member";
    }
  };

  if (isLoading) {
    return (
      <StyledPageContainer>
        <AppSpinner />
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer>
      <StyledAddButtonContainer>
        <PageAddButton text={"Add member"} onClick={handleOpenModal} />
      </StyledAddButtonContainer>

      {isAddEditMemberModalOpen && (
        <AppModal
          closeModal={handleCloseAddEditMemberModal}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          isOpen={isAddEditMemberModalOpen}
          showBackArrow
          currentTab={tabValue}
          setCurrentTab={handleTabChange}
          $customContentStyle={{ padding: 0 }}
        >
          <StyledAppModalStepsContainer>
            <TempStyledAppSideContainer>
              <AppModalMobileSteps
                tabsLength={tabsData.length}
                tabValue={tabValue}
                titlesOfSteps={[
                  "Member profile",
                  "Children info",
                  "Roles & groups",
                ]}
              />
              <AppModalDesktopSteps
                tabs={tabsData}
                tabsTitle={getTitleMemberInfo()}
                OnChangeTabChange={handleTabChange}
                tabValue={tabValue}
              />
            </TempStyledAppSideContainer>
            <AddMemberTab
              outerTabValue={tabValue}
              handleOuterTabChange={handleTabChange}
              isAutoFill={isAutoFill}
              autofillInputProps={autofillInputProps}
            />
            <ChildrenTab
              outerTabValue={tabValue}
              handleOuterTabChange={handleTabChange}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                ...(tabValue < 2 && HIDDEN_ELEMENT_STYLES),
              }}
            >
              <GroupsTab
                tabindex={2}
                addMemberTabs={tabsData}
                outerTabValue={tabValue}
                innerTabsValue={innerTabsValue}
                handleInnerTabChange={handleInnerTabChange}
                setIsModalClose={handleCloseAddEditMemberModal}
                isEditMode={isEditMode}
              />
              <GroupsSideMenu />
            </div>
          </StyledAppModalStepsContainer>
        </AppModal>
      )}

      {isViewMemberModalOpen && <ViewMemberModal />}
      {isViewChildModalOpen && <ViewChildModal />}

      <AppTable
        columns={columns}
        rows={rows || []}
        showPagination={false}
        showTotalCount={false}
        customstyles={{ tableTd: { width: "50%" } }}
        childrenFilterOptions={
          subGroupName ? (
            <div>
              <div style={{ marginBottom: "10px" }}>Search results for: </div>
              <StyledSearchContainer
                onClick={() =>
                  navigate(pathname, { replace: true, state: null })
                }
              >
                <CancelIcon />
                {subGroupName}
              </StyledSearchContainer>
            </div>
          ) : null
        }
      />

      <EditChildModal />
    </StyledPageContainer>
  );
};
