import React, { useState } from "react";
import { StyledAppModalDesktopStepTitle } from "../../../../components/shared/AppModalDesktopSteps/AppModalDesktopSteps.styles";
import {
  StyleAppModalSingleStepContainer,
  StyledAppModalSubmitButtonContainer,
} from "../../../../components/shared/StyledComponents/StyledAppModalComponents";
import { AppSideMenuContainer } from "../../../../components/shared/AppSideMenuContainer/AppSideMenuContainer";
import { PerformanceSideInfo } from "./PerformanceSideInfo/PerformanceSideInfo";
import { StyledDivider } from "../../../../components/shared/StyledComponents/StyledDivider";
import styled from "styled-components";
import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import { TEMPLATES_ATOMS } from "../AddEditTemplateModal.atoms";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import * as Yup from "yup";
import { AppFormikField } from "../../../../components/core/AppFormik/AppFormikField/AppFormikField";
import { AppButton } from "../../../../components/core/AppButton/AppButton";
import { AppSwitch } from "../../../../components/core/AppSwitch/AppSwitch";
import { AppFormikSelect } from "../../../../components/core/AppFormik/AppFormikSelect/AppFormikSelect";
import {
  StyledSwitchContainer,
  StyledSwitchLabel,
} from "./PerformancePostFields.styles";
import { firebaseHttpsCallable } from "../../../../hooks/firebase/firebase.helper";
import { FIRESTORE_FUNCTIONS } from "../../../../constants/firebase.constants";
import {
  ADD_PERFORMANCE_POST_TEMPLATE_DTO,
  PERFORMANCE_POST_TEMPLATE,
  TEMPLATE_TYPE,
} from "../../../../types/templates/templates.types";
import { useSelector } from "react-redux";
import { getClientSelector } from "../../../../redux/client/clientSlice";
import { GENERAL_RESPONSE } from "../../../../types/firbase.types";
import { APP_TOASTIFY } from "../../../../styles/styles.constants";
import { toast } from "react-toastify";
import { getUser } from "../../../../redux/user/userSlice";
import { capitalizeFirstChar } from "../../../../helpers/common";

const STAR_RATINGS = [
  {
    value: "0-5",
    label: "0-5",
  },
];

const StyledPerformancePostContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    justifyContent: "space-between",
  },
});

const StyledForm = styled(Form)({
  height: "58vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "@media only screen and (min-width: 1200px)": {
    height: "50vh",
  },
});

const StyledSelectContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    maxWidth: 350,
  },
});

export interface PERFORMANCE_POST_FIELDS {
  metricTitle: string;
  starRating: string;
}

const schema = Yup.object().shape({
  metricTitle: Yup.string().required("Required"),
  starRating: Yup.string().required("Required"),
});

export const PerformancePostFields: React.FC = () => {
  const [performancePostFields, setPerformancePostFields] = useAtom(
    TEMPLATES_ATOMS.performancePostFields
  );
  const [initialValues, setInitialValues] = useState<PERFORMANCE_POST_FIELDS>({
    metricTitle: "",
    starRating: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addAnotherMetric, setAddAnotherMetric] = useState(false);
  const client = useSelector(getClientSelector);
  const user = useSelector(getUser);
  const templateName = useAtomValue(TEMPLATES_ATOMS.templateName);
  const templateType = useAtomValue(TEMPLATES_ATOMS.templateType);
  const setIsPublished = useSetAtom(TEMPLATES_ATOMS.isPublished);
  const isEditMode = useAtomValue(TEMPLATES_ATOMS.isEditMode);
  const templateId = useAtomValue(TEMPLATES_ATOMS.templateId);
  const usableBy = useAtomValue(TEMPLATES_ATOMS.usableBy);
  const viewableBy = useAtomValue(TEMPLATES_ATOMS.viewableBy);

  const handleAddAnotherMetric = (values: PERFORMANCE_POST_FIELDS) => {
    if (values.metricTitle && values.starRating) {
      setPerformancePostFields([
        ...performancePostFields,
        {
          metricTitle: values.metricTitle,
          starRating: values.starRating,
        },
      ]);
    }
  };

  const handleEditPerformancePost = () => {
    setIsSubmitting(true);
    const payload: PERFORMANCE_POST_TEMPLATE = {
      clientId: String(client.clientId),
      templateName: capitalizeFirstChar(templateName)?.trim(),
      templateType: templateType as TEMPLATE_TYPE.performancePost,
      additionalFields: [...performancePostFields],
      updatedBy: String(user.uid),
      templateId: templateId,
      usableBy: usableBy,
      viewableBy: viewableBy,
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.editPerformancePostTemplate)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_RESPONSE;
        if (data.result === "ok") {
          toast("Performance post template updated", APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast(
            "Error while updating performance post template [1]",
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((e) =>
        toast(
          "Error while updated performance post template [2]",
          APP_TOASTIFY.ERROR
        )
      )
      .finally(() => setIsSubmitting(false));
  };

  const handleAddPerformancePost = () => {
    setIsSubmitting(true);
    const payload: ADD_PERFORMANCE_POST_TEMPLATE_DTO = {
      clientId: String(client.clientId),
      templateName: capitalizeFirstChar(templateName),
      templateType: templateType as TEMPLATE_TYPE.performancePost,
      additionalFields: [...performancePostFields],
      updatedBy: String(user.uid),
      usableBy: usableBy,
      viewableBy: viewableBy,
    };

    firebaseHttpsCallable(FIRESTORE_FUNCTIONS.addPerformancePostTemplate)({
      ...payload,
    })
      .then((res) => {
        const data = res.data as GENERAL_RESPONSE;
        if (data.result === "ok") {
          toast("Performance post template added", APP_TOASTIFY.SUCCESS);
          setIsPublished(true);
        } else {
          toast(
            "Error while adding performance post template [1]",
            APP_TOASTIFY.ERROR
          );
        }
      })
      .catch((e) =>
        toast(
          "Error while adding performance post template [2]",
          APP_TOASTIFY.ERROR
        )
      )
      .finally(() => setIsSubmitting(false));
  };

  const handlePublish = () => {
    if (isEditMode) {
      handleEditPerformancePost();
    } else {
      handleAddPerformancePost();
    }

    return;
  };

  return (
    <StyledPerformancePostContainer>
      <StyleAppModalSingleStepContainer style={{ paddingBottom: 0 }}>
        <StyledAppModalDesktopStepTitle>Fields</StyledAppModalDesktopStepTitle>
        <StyledDivider style={{ marginBottom: 40 }} />
        <Formik
          enableReinitialize={true}
          initialValues={initialValues as PERFORMANCE_POST_FIELDS}
          validationSchema={schema}
          onSubmit={(values: PERFORMANCE_POST_FIELDS) => {
            handlePublish();
          }}
        >
          {({
            errors,
            touched,
            values,
            setFieldValue,
            handleSubmit,
            handleBlur,
            setErrors,
          }) => {
            const _touched: FormikTouched<PERFORMANCE_POST_FIELDS> = touched;
            const _errors: FormikErrors<PERFORMANCE_POST_FIELDS> = errors;

            return (
              <StyledForm>
                <div>
                  <AppFormikField
                    isRequired={true}
                    name="metricTitle"
                    value={values?.metricTitle}
                    label="METRIC TITLE"
                    errorMessage={
                      _touched.metricTitle && _errors.metricTitle
                        ? String(errors.metricTitle)
                        : undefined
                    }
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("metricTitle", e.target.value);
                    }}
                    type="text"
                    containerstyle={{ marginBottom: 25 }}
                  />

                  <StyledSelectContainer>
                    <AppFormikSelect
                      isRequired
                      name="starRating"
                      label="STAR RATING (LOW-HIGH)"
                      value={values?.starRating}
                      errorMessage={
                        _touched.starRating && _errors.starRating
                          ? String(errors.starRating)
                          : undefined
                      }
                      onChange={(e) => {
                        setFieldValue("starRating", e.value);
                      }}
                      options={STAR_RATINGS}
                    />
                  </StyledSelectContainer>
                </div>

                <StyledAppModalSubmitButtonContainer>
                  <StyledSwitchContainer>
                    <AppSwitch
                      checked={addAnotherMetric}
                      onChange={(checked: boolean) => {
                        setAddAnotherMetric(checked);
                      }}
                    />
                    <StyledSwitchLabel>Add another metric</StyledSwitchLabel>
                  </StyledSwitchContainer>

                  {addAnotherMetric ? (
                    <AppButton
                      isLoading={isSubmitting}
                      onClick={() => {
                        if (values.metricTitle && values.starRating) {
                          handleAddAnotherMetric(values);
                          setFieldValue("metricTitle", "");
                          setFieldValue("starRating", "");
                          setAddAnotherMetric(false);
                        }
                        return;
                      }}
                    >
                      Next
                    </AppButton>
                  ) : (
                    <AppButton
                      isLoading={isSubmitting}
                      onClick={() => {
                        if (!isEditMode) {
                          if (values.metricTitle && values.starRating) {
                            handleAddAnotherMetric(values);
                            setFieldValue("metricTitle", "");
                            setFieldValue("starRating", "");
                          }
                          handleSubmit();
                        }
                        if (isEditMode) {
                          if (!values.metricTitle && !values.starRating) {
                            handlePublish();
                            setErrors({});
                          } else {
                            handleAddAnotherMetric(values);
                            setFieldValue("metricTitle", "");
                            setFieldValue("starRating", "");
                            handleSubmit();
                          }
                        }
                      }}
                    >
                      Publish
                    </AppButton>
                  )}
                </StyledAppModalSubmitButtonContainer>
              </StyledForm>
            );
          }}
        </Formik>
      </StyleAppModalSingleStepContainer>
      <AppSideMenuContainer hideInMobile={true}>
        <PerformanceSideInfo />
      </AppSideMenuContainer>
    </StyledPerformancePostContainer>
  );
};
