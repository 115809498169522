import { Form } from "formik";
import styled from "styled-components";
import { APP_COMMON_COLORS } from "../../../../providers/AppThemeProvider";

export const StyledForm = styled.div({
  height: "50vh",
  display: "flex",
  flexDirection: "column",
  marginBottom: 90,
  "@media only screen and (min-width: 1200px)": {
    height: "50vh",
    marginBottom: 40,
  },
});

export const StyledFormInputs = styled.div({
  //   width: 600,
});

export const StyledPermissionsStepContainer = styled.div({
  "@media only screen and (min-width: 1200px)": {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const StyledPermissionsInfosContainer = styled.div({
  display: "flex",
  gap: 5,
});

export const StyledPermissionsInfos = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  fontSize: 14,
});

export const StyledInfoTitle = styled.div(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  marginBottom: 5,
  color: theme.colorPrimary,
}));

export const StyledInfoBody = styled.div({
  fontSize: 14,
  color: APP_COMMON_COLORS.dark[600],
});
