import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { APP_COMMON_COLORS } from "../../../../../providers/AppThemeProvider";

interface NewChildInfoIconProps {
  childId?: string;
  existingChildrenIds?: string[];
}

export const NewChildInfoIcon = (props: NewChildInfoIconProps) => {
  const { childId, existingChildrenIds } = props;

  if (
    childId &&
    existingChildrenIds &&
    existingChildrenIds?.length > 0 &&
    childId === existingChildrenIds[0]
  ) {
    return (
      <Tooltip
        title={
          <div style={{ width: 120 }}>
            Child being assigned to an additional guardian
          </div>
        }
        placement="bottom-start"
        arrow
      >
        <InfoIcon
          style={{ color: APP_COMMON_COLORS.dark[500], fontSize: 22 }}
        />
      </Tooltip>
    );
  }

  return <></>;
};
