export const getUniqueChildrenIds = (
  childrenIds: string[],
  existingChildrenId: string
) => {
  if (!existingChildrenId) {
    return childrenIds;
  }

  if (!childrenIds?.length) {
    return [existingChildrenId];
  }

  if (childrenIds?.includes(existingChildrenId)) {
    return childrenIds;
  }

  return [...childrenIds, existingChildrenId];
};
