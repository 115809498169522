import { SxProps } from "@mui/material";
import styled, { CSSObject } from "styled-components";
import { Search } from "@mui/icons-material";
import { hexToRGBA } from "../../../styles/styles.constants";

export const StyledTable = styled.table({
  width: "100%",
  borderRadius: "3px",
  borderCollapse: "collapse",
});

export const StylesAppTableContainer = styled.div<{ $styles?: {} }>(
  ({ $styles }) => ({
    ...$styles,
  })
);

export const StylesTableContainer = styled.div({
  borderRadius: "10px 10px 10px 10px",
  border: "1px solid #DDDDDD",
  overflowX: "auto", //to alow scroll in x-direction (widths)
});

export const StyledTableOptionsContainer = styled.div({
  display: "flex",
});

export const StyledAppTableTitle = styled.div`
  font-weight: bold;
  font-size: 26px;
  color: grey;
  margin-bottom: 10px;
`;

export const StyledTableHeader = styled.div({
  display: "content",
  justifyContent: "space-between",
});

export const StyledFilterIconContainer = styled.div<{ isExpanded?: boolean }>`
  width: auto;
  height: 42px;
  padding: 0px;
  margin-inline: 7px;
  display: flex;
  align-items: center;
  background: grey;
  background: grey;
  justify-content: center;
  border: 1px solid grey;
  border-radius: 7px;
  &:hover {
    cursor: pointer;
    border: 1px solid grey;
  }
`;

export const AccordionFilterOptions: SxProps = {
  margin: "0px 0px 10px 0px !important",
  border: "none",
  boxShadow: "none",
  backgroundColor: "grey",
  padding: "10px 20px",
};

export const StyledFilterMenuContainer = styled.div<{ isExpanded?: boolean }>`
  margin-top: ${({ isExpanded }) => (isExpanded ? "-17px" : "0px")};
  position: "relative";
`;

export const StyledFilterMenuArrow = styled.div`
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid grey;
  margin-inline: 10px;
`;

export const StyledFilterBy = styled.div({
  lineHeight: "10px",
  color: "grey",
  margin: "3px 5px 0px 5px",
});

export const StyledExtractBtn = styled.div<{ direction?: string }>`
  display: flex;
  margin-bottom: 10px;
  justify-content: ${({ direction }) =>
    direction == "rtl" ? "left" : "right"};
`;

export const StyledIds = styled.div<{ styles?: {} }>(({ styles }) => ({
  textDecoration: "underline",
  cursor: "pointer",
  marginBottom: "8px",
  "&:hover": {
    color: "blue",
  },
  ...styles,
}));

export const StyledFlexActionButtonsContainer = styled.div({
  display: "flex",
  justifyContent: "end",
});

export const TABLE_ACTION_BUTTONS_STYLES = {
  height: "10px",
  fontSize: "14px",
  marginInline: "11px",
  background: "blue",
  borderRadius: "4px",
  color: "white",
  padding: "13px",
};

export const StyledPaginationContainer = styled.div({
  direction: "ltr",
  marginTop: 40,
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  gap: 30,
  alignItems: "center",
  "@media screen and (min-width: 600px)": {
    flexDirection: "row",
    gap: 40,
  },
});

export const getStyledPaginationItemsPerPageStyles = (
  screenWidth: number
): CSSObject => ({
  borderColor: "rgba(0, 0, 0, 0.23)",
  borderRadius: "4px",
  height: screenWidth < 1024 ? 32 : 40,
  width: 70,
});

export const StyledPaginationTotal = styled.div({
  fontSize: 14,
  alignSelf: "center",
  color: "grey",
});

export const StyledUnderTitleActionButton = styled.div({
  cursor: "pointer",
  display: "flex",
  width: "max-content",
});

export const StyledSearchIconContainer = styled.div(({ theme }) => ({
  background: hexToRGBA(theme.colorPrimary, 0.1),
  borderRadius: "50%",
  width: "47px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media only screen and (min-width: 1200px)": {
    width: "35px",
  },
}));

export const SEARCH_STYLES: CSSObject = {
  width: 225,
  marginBottom: 21,
  "@media only screen and (min-width: 1200px)": {
    width: 400,
  },
};

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.colorPrimary,
  fontSize: 16,
}));
