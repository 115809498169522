export interface TEXT_INPUT_FIELD {
  label: string;
  required: boolean;
  type: string;
}

export interface ATTACHMENT_FIELD {
  label: string;
  required: boolean;
  type: string;
}

export interface REDIRECTION_FIELD {
  label: string;
  required: boolean;
  url: string;
  checkboxText: string;
  type: string;
}

export interface SINGLE_CHOICE_FIELD {
  label: string;
  required: boolean;
  options: string[];
  type: string;
}

export type ADDED_FIELDS_TYPES =
  | TEXT_INPUT_FIELD
  | ATTACHMENT_FIELD
  | REDIRECTION_FIELD
  | SINGLE_CHOICE_FIELD;

export enum ROLE_FIELDS_TYPES {
  textInput = "textInput",
  attachment = "attachment",
  redirection = "redirection",
  singleChoice = "singleChoice",
}

export interface ADD_ROLE_TO_CLIENT_DTO {
  clientId: string;
  roleName: string;
  fields?: ADDED_FIELDS_TYPES[];
  permissions?: { [key: string]: boolean };
}

export interface EDIT_CLIENT_ROLE_DTO {
  clientId: string;
  roleName: string;
  fields?: ADDED_FIELDS_TYPES[];
  permissions?: { [key: string]: boolean };
}

export const GuardianRoleName = "Guardian";
